<!--  -->
<template>
  <div class='xyxthz'>
    <div class="qst"><xtqst :allData="allData"></xtqst></div>
    <div class="tjt">
      <div class="tjt-item"><xttj1></xttj1></div>
      <div class="tjt-item"><xttj2></xttj2></div>
      <div class="tjt-item"><xttj3></xttj3></div>
      <div class="tjt-item"><xttj4></xttj4></div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import xtqst from './xtqst.vue'
import xttj1 from './xttj1.vue'
import xttj2 from './xttj2.vue'
import xttj3 from './xttj3.vue'
import xttj4 from './xttj4.vue'
export default {
// import引入的组件需要注入到对象中才能使用
  components: { xtqst, xttj1, xttj2, xttj3, xttj4 },
  props: ['datas'],
  data () {
  // 这里存放数据
    return {
      allData: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    datas (v) {
      this.allData = v
      // // console.log(111111111, this.allData)
    }
  },
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.allData = this.datas
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.xyxthz {
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  .qst {
    width: 100%;
    height: 500px;
      border: 1px solid #03757F;
      border-radius: 6px;
      margin-bottom: 15px;
  }
  .tjt {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-item {
      width: 24%;
      height: 300px;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
  }
}
</style>

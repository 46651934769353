import { render, staticRenderFns } from "./smartElderlyCare.vue?vue&type=template&id=9466efa6&scoped=true&"
import script from "./smartElderlyCare.vue?vue&type=script&lang=js&"
export * from "./smartElderlyCare.vue?vue&type=script&lang=js&"
import style0 from "./smartElderlyCare.vue?vue&type=style&index=0&id=9466efa6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9466efa6",
  null
  
)

export default component.exports
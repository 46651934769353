<template>
  <div class="write">
    <div class="title">
      <span>护理计划</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="head">
          <span>护理计划</span>
        </div>
        <div class="add">
          <el-button class="button" size="mini">新增</el-button>
        </div>
        <div class="table">
          <el-table :data="table" height="100%" :header-cell-style="{ background: '#024276' }">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column label="工作编码">
            </el-table-column>
            <el-table-column prop="jsgx" label="计划序号">
            </el-table-column>
            <el-table-column label="护理内容">
            </el-table-column>
            <el-table-column label="服务时间">
            </el-table-column>
            <el-table-column label="执行频率">
            </el-table-column>
            <el-table-column label="任务类型">
            </el-table-column>
            <el-table-column label="操作" width="240">
              <template>
                <div class="flex">
                  <span>编辑</span>
                  <span>配置频率</span>
                  <span>删除</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="所属街道">
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    selectId: {
      type: String
    }
  },
  data () {
    return {
      table: [
        {}
      ]
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    handleSizeChange (e) {
      console.log(e)
    },
    handleCurrentChange (e) {
      console.log(e)
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding: 0 80px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    max-height: 900px;
    padding: 50px;
    padding-top: 30px;
    padding-bottom: 80px;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .border {
      border: 1px solid #01EEFD;
    }

    .close {
      position: absolute;
      top: 40px;
      right: 40px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .box {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 98%;

      .head {
        display: flex;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        span {
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          background-color: #01EEFD;
          color: #fff;
          font-size: 22px;
        }
      }

      .add {
        display: flex;
        align-items: center;
        height: 50px;

        .button {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
          border: none;
          color: #fff;
        }
      }

      .table {
        width: 100%;
        height: calc(100% - 170px);

        .flex {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
        }
      }

      ::v-deep .el-table__body-wrapper {
        &::-webkit-scrollbar {
          width: 0px;
        }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      .el-table {
        background-color: #092B50;
        border: 4px #073F73 solid;
      }

      // 表头文字颜色
      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      //表格内容颜色
      /deep/.el-table__body tbody tr {
        color: #fff;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      // 改变边框颜色
      .el-table--border,
      .el-table--group {
        border: 2px solid #ddd !important;
      }

      /**
  改变表格内竖线颜色
   */
      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: 1px solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: 1px solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: 1px solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }

      /deep/.el-checkbox__inner {
        background-color: transparent;
      }

      ::v-deep .el-table__empty-text {
        color: #01EEFD;
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #01EEFD;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
    }
  }

}
</style>

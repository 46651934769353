<template>
  <div class="write">
    <div class="title">
      <span>下级机构</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div @click="canvasCharts" class="charts" id="charts"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      myChart: null
    }
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
      }, 100)
    },
    closePopup () {
      this.$emit('close')
    },
    addColor (color1, color2) {
      return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: color1
      }, {
        offset: 1,
        color: color2
      }])
    },

    canvasCharts () {
      this.chartDom = document.getElementById('charts')
      this.myChart = this.$echarts.init(this.chartDom)
      var option
      var colors = []
      colors.push(this.addColor('#F7903C', '#423635'))
      colors.push(this.addColor('#02DFF1', '#423635'))
      colors.push(this.addColor('#FF2B70', '#423635'))
      colors.push(this.addColor('#C5FF00', '#423635'))
      colors.push(this.addColor('#6B2BFF', '#423635'))
      colors.push(this.addColor('#60FF86', '#423635'))
      const axisLabel = {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 13
        }
      }
      const series = []
      const seriesTitles = ['服务人数', '男性', '女性', '高血压', '糖尿病', '独居人群']
      seriesTitles.forEach(item => {
        series.push(
          {
            type: 'bar',
            name: item,
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: 10
                  }
                }
              }
            },
            barWidth: 18
          }
        )
      })
      option = {
        color: colors,
        legend: {
          show: true,
          data: seriesTitles,
          textStyle: {
            color: '#ffffff'
          }
        },
        tooltip: {},
        dataset: {
          source: [
            ['白家庄村卫生室', 43.3, 85.8, 93.7, 58],
            ['东阳台村卫生室', 83.1, 73.4, 55.1, 42],
            ['西安庄四村卫生室', 86.4, 65.2, 82.5, 33],
            ['东安庄一村卫生室', 72.4, 53.9, 39.1, 22],
            ['大魏村卫生室', 72.4, 53.9, 39.1, 22],
            ['西安庄一村卫生室', 72.4, 53.9, 39.1, 22],
            ['小魏村卫生室', 72.4, 53.9, 39.1, 22]
          ]
        },
        xAxis: {
          type: 'category',
          axisLabel
        },
        yAxis: {
          axisLabel
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            show: false, // 是否显示滑动条，不影响使用
            type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 6 // 一次性展示多少个。
          }
        ],
        series
      }
      this.myChart.setOption(option)
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.$nextTick(() => {
      this.canvasCharts()
      window.addEventListener('resize', this.getresize)
    })
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  activated () {
    // console.log('组件激活')
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 90%;
    height: 546px;
    margin-top: 10px;
    padding: 20px 0;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 22px;
      right: 30px;
      z-index: 888;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .box {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }

    .charts {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

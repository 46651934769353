<template>
  <div class="charge">
    <div class="title">
      <span>收费</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="left">
          <div class="info">
            <div class="news">
              <div class="img"></div>
              <div class="context">
                <span>张芳</span>
                <span>女/33岁</span>
                <span>13578956236</span>
              </div>
              <div class="vip">
                <span>优享会员</span>
              </div>
            </div>
            <div class="label">
              <div class="item">
                <span>高血压</span>
              </div>
              <div class="item">
                <span>糖尿病</span>
              </div>
              <div class="item">
                <span class="el-icon-plus"></span>
              </div>
            </div>
          </div>
          <div class="line">
            <div class="line-title">
              <span>体格特征</span>
            </div>
            <div class="text">
              <span class="span">体温：37℃</span>
              <span class="span1">血压：80/160mmhg</span>
            </div>
            <div class="text">
              <span class="span">脉搏：60次/分</span>
              <span class="span">体重：50kg</span>
            </div>
            <div class="text">
              <span class="span">呼吸：70次/分</span>
              <span class="span">身高：170cm</span>
            </div>
          </div>
          <div class="line">
            <div class="line-title">
              <span>就诊日志</span>
            </div>
            <div class="text">
              <span>2021-11-03 10:00:00</span>
            </div>
            <div class="text">
              <span>初步诊断：上呼吸道感染</span>
            </div>
            <div class="text">
              <span>就诊医生：王志 内科</span>
            </div>
          </div>
          <div class="line">
            <div class="text">
              <span>2021-11-03 10:00:00</span>
            </div>
            <div class="text">
              <span>初步诊断：上呼吸道感染</span>
            </div>
            <div class="text">
              <span>就诊医生：王志 内科</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="table-title">
            <span>项目收费</span>
          </div>
          <div class="talbe">
            <el-table :data="tableData" height="100%" style="width: 100%">
            <el-table-column prop="date" label="项目名称">
            </el-table-column>
            <el-table-column prop="name" label="结算类型">
            </el-table-column>
            <el-table-column prop="date" label="规格">
            </el-table-column>
            <el-table-column prop="date" label="批号">
            </el-table-column>
            <el-table-column prop="date" label="生产厂家">
            </el-table-column>
            <el-table-column prop="date" label="零售单位">
            </el-table-column>
            <el-table-column prop="date" label="执行地点">
            </el-table-column>
            <el-table-column prop="date" label="数量">
            </el-table-column>
            <el-table-column prop="date" label="单价">
            </el-table-column>
            <el-table-column prop="date" label="总金额">
            </el-table-column>
            <el-table-column prop="date" label="折扣">
            </el-table-column>
            <el-table-column prop="date" label="折扣">
            </el-table-column>
            <el-table-column prop="date" label="状态">
            </el-table-column>
          </el-table>
          </div>

          <div class="tip">
            <span>原价：36.00</span>
            <span>折后金额：35.00</span>
            <span>实收：35.00</span>
          </div>

          <el-button class="button" @click="openTip">收费</el-button>
        </div>
      </div>
    </div>
    <Tip v-if="showTip" @close="closeTip"></Tip>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tip from './tip.vue'
export default {
  components: {
    Tip
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-08',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-06',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      showTip: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () { },
  methods: {
    openTip () {
      this.showTip = true
    },
    closeTip () {
      this.showTip = false
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.charge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8888;
  width: 100%;
  height: 100%;
  padding: 0 80px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    max-height: 900px;
    padding: 50px;
    padding-top: 40px;
    padding-bottom: 70px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 40px;
      right: 40px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .box {
      display: flex;
      width: 100%;
      height: 100%;

      .left {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        width: 460px;
        height: 100%;
        padding: 0 24px;
        border-right: 2px dotted #fff;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .info {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 8px 0;

          .news {
            position: relative;
            display: flex;
            height: 85px;

            .img {
              width: 60px;
              height: 60px;
              background-color: #fff;
              border-radius: 50%;
            }

            .context {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
              margin-left: 10px;
              color: #01EEFD;
              font-size: 18px;
            }

            .vip {
              position: absolute;
              top: 0;
              right: 125px;
              color: #FF0000;
              font-size: 20px;
            }
          }

          .label {
            display: flex;
            align-items: center;
            width: 100%;
            height: 42px;

            .item {
              width: 80px;
              height: 30px;
              line-height: 30px;
              margin-right: 20px;
              text-align: center;
              background-color: #03BCD7;
              color: #fff;
              cursor: pointer;
            }
          }
        }

        .line {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 158px;
          border-top: 2px dotted #fff;

          .line-title {
            color: #01EEFD;
            font-size: 18px;
          }

          .text {
            display: flex;
            width: 100%;
            color: #01EEFD;
            font-size: 16px;

            .span {
              width: 40%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .span1 {
              width: 60%;
            }
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        width: calc(100% - 460px);
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        .table-title {
          height: 40px;
          line-height: 40px;
          color: #01EEFD;
          font-size: 18px;
        }

        .talbe {
          width: 100%;
          height: 300px;
        }

        .tip {
          margin-top: 20px;
          color: #01EEFD;
          font-size: 16px;

          span {
            margin-right: 30px;
          }
        }

        .button {
          width: 70px;
          height: 38px;
          line-height: 30px;
          text-align: center;
          margin: 40px auto 0;
          padding: 0;
          border: none;
          border-radius: 0;
          background-color: #024A83;
          color: #fff;
          font-size: 18px;
        }

        // 表格样式修改
        .el-table {
          background-color: #081C38;
          border: 4px solid #073F73;
          box-sizing: border-box;

          &::before {
            background-color: transparent;
          }

          // 表头首行
          /deep/th.el-table__cell.is-leaf {
            border-bottom: none;
          }

          /deep/td.el-table__cell {
            border-bottom: 1px solid #01EEFD;
          }

          // 表头背景颜色
          /deep/th.el-table__cell {
            background-color: #024276;
          }

          /deep/.el-table__cell {
            text-align: center;
            color: #fff;
          }

          /deep/tr {
            background-color: #081C38;
          }

          /deep/.el-table__body tr:hover>td.el-table__cell {
            background-color: transparent !important;
          }

          /deep/.el-checkbox__inner {
            background-color: transparent;
          }

          /deep/.is-indeterminate .el-checkbox__inner,
          /deep/.is-checked .el-checkbox__inner {
            background-color: #04B4D0;
          }

          /deep/.el-table__body-wrapper::-webkit-scrollbar {
            width: 0;
          }

          /deep/.el-table__empty-text {
            color: #fff;
          }

          .flex {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          }
        }
      }
    }
  }

}
</style>

<template>
  <div class="write">
    <div class="title">
      <span>待办目录</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="key">姓名</span>
          <span class="value">{{ info.name || '-' }}</span>
        </div>

        <div class="item">
          <span class="key">时间</span>
          <span class="value">{{ info.fwsj || '-' }}</span>
        </div>

        <div class="item">
          <span class="key">身份证号</span>
          <span class="value">{{ info.sfzh || '-' }}</span>
        </div>

        <div class="item">
          <span class="key">责任人</span>
          <span class="value">{{ info.jiesr || '-' }}</span>
        </div>

        <div class="item">
          <span class="key">处理人</span>
          <span class="value">{{ info.czry || '-' }}</span>
        </div>

        <div class="item">
          <span class="key">是否推送：{{ info.tszt === '0' ? '否' : '是' }}</span>
        </div>

        <div class="item item1">
          <span class="key">处理状态</span>
          <span class="value red">处理中</span>
        </div>

        <div class="item item1 item2">
          <span class="key">处理意见:</span>
          <el-input type="textarea" v-model="info.fwnr">
          </el-input>
        </div>
      </div>
      <div class="btns">
        <el-button @click="close">取消</el-button>
        <el-button class="button" @click="submit">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectId: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      info: {
        age: '',
        bz1: '',
        bz2: '',
        czry: '',
        czrybm: '',
        delflag: '',
        fwjb: '',
        fwjbbm: '',
        fwnr: '',
        fwry: '',
        fwrybm: '',
        ggxxid: '',
        gjjb: '',
        gjjbbm: '',
        gjlx: '',
        gjnr: '',
        gjzb: '',
        hljb: '',
        hljbbm: '',
        id: 0,
        jiesr: '',
        jiesrbm: '',
        name: '',
        openid: '',
        rqfl: '',
        sfzh: '',
        ssjg: '',
        ssjgbh: '',
        ssjgmbgh: '',
        ssjgmc: '',
        tszt: '',
        unionid: '',
        wlbh: '',
        ylzsbh: '',
        fwsj: ''
      },
      radio: '1',
      data: [{
        id: 1,
        label: '首页',
        children: [{
          id: 4,
          label: '首页统计数据图表1'
        },
        {
          id: 5,
          label: '首页统计数据图表2'
        }]
      }, {
        id: 2,
        label: '其他页面1',
        children: []
      }, {
        id: 3,
        label: '其他页面2',
        children: []
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-123512963
      try {
        const { data: res } = await this.$http.post('/zhyyapi/ykdzhylfwlb/' + this.selectId)
        console.log('待办目录根据id告警信息列表详情', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.info = res.data
      } catch (error) {
        console.log('待办目录根据id告警信息列表详情失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async submit () {
      try {
        // 接口文档 https://app.apifox.com/project/2726972/apis/api-81591711
        const res = await this.$http.post('ykdzhylgjxxs', this.info)
        console.log('待办目录智慧养老告警信息保存', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.close()
      } catch (error) {
        console.log('待办目录智慧养老告警信息保存请求失败', error.response.data.msg)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 400px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 160px;
    height: 40px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 40px;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 10rem;
    height: 433px;
    margin-top: 10px;
    padding: 50px;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      align-items: center;

      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
      }

      .value {
        color: #fff;
        font-size: 16px;
      }

      .red {
        color: #FF0000;
      }

      /deep/.el-textarea__inner {
        height: 116px;
        resize: none;
      }
    }

    .item1 {
      width: 100%;
    }

    .item2 {
      align-items: initial;
    }

    .btns {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  .el-radio-group {
    margin-top: 6px;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }
}
</style>

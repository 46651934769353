<template>
  <div class="write">
    <div class="title">
      <span>详情信息</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="head">
          <img src="../img/emr/user.png" class="img">
          <div class="info">
            <div class="line">
              <div class="item">
                <span class="span">姓名：{{ info.name || '-' }}</span>
              </div>
              <div class="item">
                <span class="span">护理级别：</span>
                <el-select v-model="info.hldjbm" size="mini" @change="hldjChange">
                  <el-option v-for="item in hldjs" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="item">
                <span class="span">关注级别：</span>
                <el-select v-model="info.fwdjbm" size="mini" @change="gzjbChange">
                  <el-option v-for="item in gzjbs" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div> -->
              <div class="item">
                <span class="span">自理能力：</span>
                <el-select v-model="info.fwdjbm" size="mini" @change="gzjbChange">
                  <el-option v-for="item in zlnls" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="line">
              <div class="item">
                <!-- <span class="span">楼层:{{ info.fwlbl || '-' }} 层 房间号:{{ info.fwlbfjxx || '-' }}</span> -->
                <span class="span">楼层:{{ info.louce || '-' }} 层 房间号:{{ info.fjh || '-' }}</span>
              </div>
              <div class="item">
                <span class="span">入院诊断：</span>
                <el-input size="mini" placeholder="请输入" v-model="info.rzzd"></el-input>
              </div>
              <div class="item">
                <span class="span">主管医生：</span>
                <el-select size="mini" v-model="info.fwzgys" @change="ysChange">
                  <el-option v-for="item in yss" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="line">
              <div class="item">
                <span class="span">手机号：{{ info.lxdh || '-' }}</span>
              </div>
              <div class="item">
                <span class="span">主管护士：</span>
                <el-select size="mini" v-model="info.fwzghs" @change="hsChange">
                  <el-option v-for="item in hss" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="item">
                <span class="span">主管护工：</span>
                <el-select size="mini" v-model="info.fwzrhly" @change="hgChange">
                  <el-option v-for="item in hgs" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="line line1">
              <div class="item1">
                <span>证件号码：{{ info.sfzh || '-' }}</span>
              </div>
              <div class="item1">
                <span>调换床位：</span>
              </div>
              <div class="item1">
                <span>楼层：</span>
                <el-select size="mini" v-model="info.louce" placeholder="" @change="lcChange">
                  <el-option v-for="item in lcs" :key="item.id" :label="item.louce" :value="item.louceid">
                  </el-option>
                </el-select>
              </div>
              <div class="item1">
                <span>房间号：</span>
                <el-select size="mini" v-model="info.fjh" placeholder="" @change="fjChange">
                  <el-option v-for="item in fjs" :key="item.id" :label="item.fjh" :value="item.fjid">
                  </el-option>
                </el-select>
              </div>
              <div class="item1">
                <span>床位号：</span>
                <el-select size="mini" v-model="info.cwh" placeholder="" @change="cwChange">
                  <el-option v-for="item in cws" :key="item.id" :label="item.cwh" :value="item.cwhid">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="table-title">
              <span>家属信息</span>
            </div>
            <div class="table-box">
              <el-table :data="info.fwlbjsxx" height="120" :header-cell-style="{ background: '#024276' }">
                <el-table-column label="家属姓名">
                  <template slot-scope="scope">
                    <el-input :class="{ border: !isInput }" :disabled="isInput" v-model="scope.row.jsxm"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="jsgx" label="与老人关系">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.jsgx" placeholder="请选择" :disabled="isInput"
                      :class="{ border: !isInput }">
                      <el-option v-for="item in jsgxs" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="联系方式">
                  <template slot-scope="scope">
                    <el-input :class="{ border: !isInput }" :disabled="isInput" v-model="scope.row.lxfs"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                  <template>
                    <!-- slot-scope="scope" -->
                    <div class="flex">
                      <span>编辑</span>
                      <span>删除</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <!-- <div class="table">
                <div class="thead">
                  <div class="td">
                    <span>家属姓名</span>
                  </div>
                  <div class="td">
                    <span>与老人关系</span>
                  </div>
                  <div class="td td2">
                    <span>联系方式</span>
                  </div>
                  <div class="td">
                    <span>操作</span>
                  </div>
                </div>
                <div class="tscroll">
                  <div class="thead" v-for="item in info.fwlbjsxx" :key="item.id">
                    <div class="td">
                      <span>{{ item.jsxm }}</span>
                      <input :disabled="isInput" class="input" v-model="item.jsxm">
                    </div>
                    <div class="td">
                      <span>{{ ['配偶','⽗母','⼦⼥','兄弟姐妹','其他'][item.jxgsbm - 1] }}</span>
                      <span>{{ item.jsgx || '' }}</span>
                      <input :disabled="isInput" class="input" v-model="item.jsgx">
                    </div>
                    <div class="td td2">
                      <span>{{ item.lxfs || '-' }}</span>
                      <input :disabled="isInput" class="input" v-model="item.lxfs">
                    </div>
                    <div class="td td3">
                      <span>编辑</span>
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="add" @click="update">
                <span class="el-icon-plus"></span>
              </div>
            </div>

          </div>
        </div>
        <div class="list">
          <span>选择床位</span>
          <span class="span">床位费合计：{{ info.cwfhj || '-' }}</span>
        </div>
        <div class="list list1">
          <div class="item">
            <span class="key">床位</span>
            <!-- info.fwlbcwxx -->
            <el-select v-model="info.cwh">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="key">床位单价</span>
            <el-input placeholder="" disabled v-model="info.cwyjg"></el-input>
            <!-- <el-select v-model="info.cwyjg">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select> -->
          </div>
          <div class="item">
            <span class="key">入住天数</span>
            <el-input placeholder="请输入" v-model="info.rzts"></el-input>
          </div>
          <div class="item">
            <span class="key">入住日期</span>
            <el-date-picker v-model="info.rzsj" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange">
            </el-date-picker>
          </div>
        </div>
        <div class="list list2">
          <span>餐饮膳食</span>
          <span class="span">伙食费合计：{{ info.cyhsfhj || '-' }}</span>
        </div>
        <div class="list list1">
          <div class="item">
            <span class="key">套餐单价</span>
            <el-input placeholder="请输入" v-model="info.cytcdj"></el-input>
          </div>
        </div>
        <div class="list list2">
          <span>护理服务</span>
          <span class="span">护理费合计：{{ info.hlfhj || '-' }}</span>
        </div>
        <div class="list list1">
          <div class="item item1">
            <span class="key">护理费</span>
            <el-input placeholder="请输入" class="input"></el-input>
            <div class="add" @click="updates">
              <span>添加</span>
            </div>
          </div>
          <div class="item item1">
            <span class="key">其他收费</span>
          </div>
        </div>
        <div class="context">
          <div class="table">
            <!-- <div class="thead thead1">
              <div class="td">
                <span>服务项目</span>
              </div>
              <div class="td">
                <span>服务内容</span>
              </div>
              <div class="td">
                <span>合计</span>
              </div>
              <div class="td border">
                <span>操作</span>
              </div>
            </div>
            <div class="tscroll">
              <div class="thead" v-for="item in info.fwlbhlfw" :key="item.id">
                <div class="td">
                  <span>{{ item.fwxm || '-' }}</span>
                </div>
                <div class="td">
                  <span>{{ item.fwnr || '-' }}</span>
                </div>
                <div class="td">
                  <span>{{ item.fwjg || '-' }}</span>
                </div>
                <div class="td flex">
                  <span>编辑</span>
                  <span>删除</span>
                </div>
              </div>
            </div> -->
            <el-table :data="info.fwlbhlfw" height="100%" :header-cell-style="{ background: '#024276' }">
              <el-table-column label="服务项目">
                <template slot-scope="scope">
                  <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.fwxm"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="jsgx" label="服务内容">
                <template slot-scope="scope">
                  <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.fwnr"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="费用类型">
                <!-- <template slot-scope="scope">
                    <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.fwnr"></el-input>
                  </template> -->
              </el-table-column>
              <el-table-column label="合计">
                <template slot-scope="scope">
                  <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.fwjg"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template>
                  <!-- slot-scope="scope" -->
                  <div class="flex">
                    <span>编辑</span>
                    <span>删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="info">
            <div class="info-line">
              <div class="item">
                <span class="key">押金</span>
                <el-input placeholder="请输入" class="input" v-model="info.qtsfyj"></el-input>
              </div>
              <div class="item item1">
                <span class="key">一次性生活费</span>
                <el-input placeholder="请输入" class="input" v-model="info.ycxshf"></el-input>
              </div>
            </div>
            <div class="info-line">
              <div class="item">
                <span class="key">入院费用核定周期设置</span>
              </div>
            </div>
            <div class="info-line">
              <div class="item">
                <span class="key">入院费用核定天数 </span>
                <el-input placeholder="请输入" class="input" v-model="info.rzfyhdts"></el-input>
              </div>
            </div>
          </div>
        </div>
        <el-button class="submit" @click="submit">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { createNew } from '../../../utils/print/tsc.js'
export default {
  props: {
    selectId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      hldjs: [{
        value: '1',
        label: '一级 '
      }, {
        value: '2',
        label: '二级 '
      }, {
        value: '3',
        label: '三级 '
      }, {
        value: '4',
        label: '四级 '
      }],
      gzjbs: [{
        value: '1',
        label: '普通关注 '
      }, {
        value: '2',
        label: '重点关注 '
      }],
      zlnls: [{
        value: '1',
        label: '完好 '
      }, {
        value: '2',
        label: '轻度受损 '
      }, {
        value: '3',
        label: '中重度受损 '
      }, {
        value: '4',
        label: '重度受损 '
      }],
      jsgxs: [{
        value: '1',
        label: '配偶 '
      }, {
        value: '2',
        label: '⽗母 '
      }, {
        value: '3',
        label: '⼦⼥ '
      }, {
        value: '4',
        label: '兄弟姐妹 '
      }, {
        value: '5',
        label: '其他 '
      }],
      value: '',
      info: {
        id: null,
        createTime: '',
        updateTime: '',
        name: '',
        age: '',
        ssjgmc: null,
        lxdh: '',
        ssjg: null,
        sbxh: null,
        jcry: null,
        jcjgbh: null,
        sfzh: '',
        jkzd: null,
        rlbh: null,
        fwrybm: null,
        fwry: null,
        fwzgys: '',
        fwzgysbm: '',
        fwzghs: '',
        fwzghsbm: '',
        fwzrhly: '',
        fwzrhlybm: '',
        xued: '',
        xueyg: '',
        tw: '',
        xinlv: '',
        xuey: '',
        bushu: null,
        xind: null,
        qtjc: null,
        sos: null,
        anquan: null,
        gouwu: null,
        dsws: null,
        cwzt: null,
        ranq: null,
        yanw: null,
        wshui: null,
        fwdj: '',
        fwdjbm: '',
        yzxh: null,
        tel: null,
        jsxh: null,
        openid: null,
        wllng: null,
        wllat: null,
        address: '',
        rqfl: null,
        bz1: null,
        bz2: null,
        bz3: null,
        bz4: null,
        rzts: '',
        rzkssj: '',
        rzjssj: '',
        rzyz: null,
        rzzd: null,
        rzzdbm: '',
        rzzs: null,
        rzbl: null,
        blxh: null,
        jslxfs: null,
        jsgx: null,
        dzwlxh: null,
        qyxuh: null,
        zsfw: null,
        hldj: '',
        hldjbm: '',
        cgxx: null,
        lng: null,
        lat: null,
        zbfw: null,
        gjflag: null,
        ylzsbh: '',
        ssjgmbgh: '',
        ssjgbh: '',
        ylfwjlid: '',
        xb: '',
        fwlblc: '',
        fwlblcbm: '',
        fwlbfjxx: '',
        fwlbfjbm: '',
        fwlbcwxx: null,
        fwlbcwbm: '',
        xuet: '',
        rzsj: '',
        cwdj: '',
        cwfhj: '',
        cyhsfhj: '',
        cytcdj: '',
        hlfhj: '',
        qtsfyj: '',
        ycxshf: '',
        rzfyhdts: '',
        fwlbjsxx: [],
        fwlbhlfw: [],
        cwyjg: ''
      },
      isInput: true,
      isInputs: true,
      yss: [],
      hss: [],
      hgs: [],
      lcs: [],
      louceid: '',
      louc: '',
      fjs: [],
      fjid: '',
      cws: [],

      currentTime: 2,
      // 打印计数
      printCount: 1,
      // 当前打印计数
      currentCount: 1
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    this.getInfo()
    this.getYsList()
    this.getHsList()
    this.getHgList()
    this.getLcList()
  },
  methods: {
    async connect () {
      try {
        const device = await navigator.bluetooth.requestDevice({
          filters: [{ services: ['e7810a71-73ae-499d-8c15-faa9aef0c3f2'] }]
        })
        const server = await device.gatt.connect()
        console.log(server)
        const service = await server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2')
        console.log(service)
        this.characteristic = await service.getCharacteristic('bef8d6c9-9c21-4c9e-b632-bd58c1009f9f')
        console.log(this.characteristic)
        // const date = new Uint8Array(this.buf)
        // this.characteristic.writeValue(date).then(res => {
        //   console.log('res', res)
        // }).catch(err => {
        //   console.log(err)
        // })
      } catch (error) {
        console.error('连接蓝牙打印机失败', error)
      }
    },
    // 佳博打印 设置打印内容
    setPrintContent () {
      const command = createNew()
      command.setCls()
      setTimeout(() => {
        command.setSize('40', '120')
        command.setGap(1)
        command.setCls()
        // command.setText(40, 38, 'TSS24.BF2', 0, 1, 1, '日期：2022-02-02')
        // command.setText(40, 72, 'TSS24.BF2', 0, 1, 1, '李四 女 年龄：23')
        // command.setBarCode(40, 100, '128', 60, 0, 0, 2, 2, '3023645644545')
        // command.setText(40, 170, 'TSS24.BF2', 0, 1, 1, '3023645644545')
        command.setBox(-70, 50, 400, 940, 1)
        command.setText(70, 80, 'TSS24.BF2', 0, 2, 2, '详情信息')
        command.setBar(-70, 150, 470, 1)

        command.setText(-60, 170, 'TSS24.BF2', 0, 1, 1, '编号:33384528899')
        command.setText(200, 170, 'TSS24.BF2', 0, 1, 1, '关注级别:')
        command.setBar(-70, 220, 470, 1)

        command.setText(-60, 240, 'TSS24.BF2', 0, 1, 1, '李明明  男  25岁  130133199909090909')
        command.setText(-60, 290, 'TSS24.BF2', 0, 1, 1, '楼层')
        command.setText(100, 290, 'TSS24.BF2', 0, 1, 1, '房间')
        command.setText(250, 290, 'TSS24.BF2', 0, 1, 1, '床位')
        command.setBar(-70, 330, 470, 1)

        command.setText(-60, 350, 'TSS24.BF2', 0, 1, 1, '入院诊断:')
        command.setText(-60, 410, 'TSS24.BF2', 0, 1, 1, '入院时间')
        command.setBar(-70, 450, 470, 1)

        command.setText(-60, 470, 'TSS24.BF2', 0, 1, 1, '主管医生:')
        command.setText(150, 470, 'TSS24.BF2', 0, 1, 1, '主管护士:')
        command.setText(-60, 520, 'TSS24.BF2', 0, 1, 1, '护理级别:')
        command.setText(150, 520, 'TSS24.BF2', 0, 1, 1, '主管护工:')
        command.setBar(-70, 560, 470, 1)

        command.setQrcode(70, 640, 'L', '10', 'M', '18464156456')
        command.setPagePrint()
        this.Send(command.getData())
      }, 1500)
    },
    // 发送指令
    async Send (buff) {
      let currentTime = this.currentTime
      const loopTime = parseInt(buff.length / 20) + 1
      const lastData = parseInt(buff.length % 20)
      let currentCount = this.currentCount
      let buf = ''
      let dataView = ''
      if (currentTime < loopTime) {
        buf = new ArrayBuffer(20)
        dataView = new DataView(buf)
        for (var i = 0; i < 20; ++i) {
          dataView.setUint8(i, buff[(currentTime - 1) * 20 + i])
        }
      } else {
        buf = new ArrayBuffer(lastData)
        dataView = new DataView(buf)
        for (var j = 0; j < lastData; ++j) {
          dataView.setUint8(j, buff[(currentTime - 1) * 20 + j])
        }
      }
      const date = new Uint8Array(buf)
      await this.characteristic.writeValue(date)
      currentTime++
      if (currentTime <= loopTime) {
        this.currentTime = currentTime
        this.Send(buff)
      } else {
        if (currentCount === this.printCount) {
          this.currentTime = 1
          this.currentCount = 1
        } else {
          currentCount++
          this.currentCount = currentCount
          this.currentTime = 1
          this.Send(buff)
        }
      }
    },

    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-122836962
      try {
        const { data: res } = await this.$http.post('/zhyyapi/ykdzhylfwlb/' + this.selectId)
        console.log('根据id获取服务列表详情', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.info = res.data
        this.isInput = true
        // this.info.fwlbjsxx = [
        //   { jsxm: '张三', jsgx: '父母', lxfs: '1111111111111' }
        // ]
        // this.info.fwlbhlfw = [
        //   { fwxm: '张三', fwnr: '父母', fwjg: '1111111111111' }
        // ]
      } catch (error) {
        console.log('根据id获取服务列表详情失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    update () {
      console.log('是否包含fwlbjsxx', 'fwlbjsxx' in this.info)
      if (!('fwlbjsxx' in this.info)) return
      const obj = {
        id: '',
        jsxm: '',
        jsgx: '',
        jxgsbm: '',
        lxfs: '',
        deflag: '',
        wxopenid: '',
        mpopenid: '',
        bz1: ''
      }
      if (this.info.fwlbjsxx === null) {
        this.info.fwlbjsxx = []
      }
      this.info.fwlbjsxx.unshift(obj)
      this.isInput = false
    },
    updates () {
      console.log('是否包含fwlbhlfw', 'fwlbhlfw' in this.info)
      if (!('fwlbhlfw' in this.info)) return
      const obj = {
        id: '',
        fwxm: '',
        fwnr: '',
        fwbm: '',
        fwjg: '',
        qt: ''
      }
      if (this.info.fwlbhlfw === null) {
        this.info.fwlbhlfw = []
      }
      console.log(this.info.fwlbhlfw)
      this.info.fwlbhlfw.unshift(obj)
      this.isInputs = false
    },
    dateChange (e) {
      console.log(e)
      // this.info.rzsj = e[0] + ',' + e[1]
      this.info.rzts = this.days(new Date(e[0]), new Date(e[1]))
    },
    days (start, end) {
      return Math.ceil(Math.abs(start - end) / 86400000)
    },
    jsgxChange (e, id) {
      console.log(e, id)
    },
    async getYsList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-122319876
      try {
        const obj = {
          jgbh: this.loginInfo.jgbh || '',
          cwhmcid: this.loginInfo.tjjgcwhmcid || '',
          lytype: '1'
        }
        const { data: res } = await this.$http.post('/users/getyslb', obj)
        console.log('获取医生列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.yss = res.data
      } catch (error) {
        console.log('获取医生列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    ysChange (e) {
      console.log(e)
      this.info.fwzgysbm = e
      const obj = this.yss.find(item => item.value === e)
      this.info.fwzgys = obj.label
      console.log(this.info.fwzgys)
    },
    async getHsList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-122319876
      try {
        const obj = {
          jgbh: this.loginInfo.jgbh || '',
          cwhmcid: this.loginInfo.tjjgcwhmcid || '',
          lytype: '2'
        }
        const { data: res } = await this.$http.post('/users/getyslb', obj)
        console.log('获取护士列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.hss = res.data
      } catch (error) {
        console.log('获取护士列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    hsChange (e) {
      console.log(e)
      this.info.fwzghsbm = e
      const obj = this.hss.find(item => item.value === e)
      this.info.fwzghs = obj.label
      console.log(this.info.fwzghs)
    },
    async getHgList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-122319876
      try {
        const obj = {
          jgbh: this.loginInfo.jgbh || '',
          cwhmcid: this.loginInfo.tjjgcwhmcid || '',
          lytype: '3'
        }
        const { data: res } = await this.$http.post('/users/getyslb', obj)
        console.log('获取护工列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.hgs = res.data
      } catch (error) {
        console.log('获取护工列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    hgChange (e) {
      console.log(e)
      this.info.fwzrhlybm = e
      const obj = this.hgs.find(item => item.value === e)
      this.info.fwzrhly = obj.label
      console.log(this.info.fwzrhly)
    },
    hldjChange (e) {
      console.log(e)
      this.info.hldj = this.hldjs[e - 1].label
      // this.info.hldjbm = e
    },
    gzjbChange (e) {
      console.log(e)
      this.info.fwdj = this.gzjbs[e - 1].label
      // this.info.fwdjbm = e
    },
    async getLcList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-117949643
      try {
        const { data: res } = await this.$http.post('/zhyyapi/findlcwsfjlb', {
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          lytype: 'lc',
          louceid: '',
          cwid: '',
          fjid: ''
        })
        console.log('楼层列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.lcs = res.data
      } catch (error) {
        console.log('楼层列表失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    lcChange (e) {
      console.log(e)
      this.info.dzlcbm = e
      const obj = this.lcs.find(item => item.louceid === e)
      this.info.dzlc = obj.louce
      console.log(this.info.dzlc)
      this.fjs = []
      this.cws = []
      this.info.dzfj = ''
      this.info.dzfjbm = ''
      this.info.dzcw = ''
      this.info.dzcwbm = ''
      this.getFjList()
    },
    async getFjList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-117949643
      try {
        const { data: res } = await this.$http.post('/zhyyapi/findlcwsfjlb', {
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          lytype: 'fj',
          louceid: this.info.dzlcbm,
          cwid: '',
          fjid: ''
        })
        console.log('房间列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.fjs = res.data
      } catch (error) {
        console.log('房间列表失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    fjChange (e) {
      this.info.dzfjbm = e
      const obj = this.fjs.find(item => item.fjid === e)
      this.info.dzfj = obj.fjh
      console.log(this.info.dzfj)
      this.cws = []
      this.info.dzcw = ''
      this.info.dzcwbm = ''
      this.getCwList()
    },
    async getCwList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-117949643
      try {
        const { data: res } = await this.$http.post('/zhyyapi/findlcwsfjlb', {
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          lytype: 'fjcw',
          louceid: this.info.dzlcbm,
          cwid: '',
          fjid: this.info.dzfjbm
        })
        console.log('床位列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.cws = res.data
      } catch (error) {
        console.log('床位列表失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    cwChange (e) {
      this.info.dzcwbm = e
      console.log(e)
      const obj = this.cws.find(item => item.cwhid === e)
      this.info.dzcw = obj.cwh
      this.info.cwyjg = obj.cwyjg
      console.log(this.info.dzcw)
    },
    async submit () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-81591713
      // const info = JSON.parse(JSON.stringify(this.info))
      // this.$delete(info, 'fwlbjsxx')
      // this.$delete(info, 'fwlbhlfw')
      this.info.rzsj = this.info.rzsj + ''
      console.log(this.info)
      try {
        const res = await this.$http.post('/ykdzhylfwlbs', this.info)
        console.log('智慧养老服务保存', res)
        if (res.status !== 200 || res.data.code !== 0) return this.$message.error(res.data.msg || res.data.message)
        this.$message.success('保存成功')
        this.$parent.getData()
        this.closePopup()
      } catch (error) {
        console.log('智慧养老服务保存失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    max-height: 900px;
    padding: 50px;
    padding-top: 30px;
    padding-bottom: 80px;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .border {
      border: 1px solid #01EEFD;
    }

    .close {
      position: absolute;
      top: 40px;
      right: 40px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .box {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 98%;

      .head {
        display: flex;
        align-items: center;
        width: 100%;

        .img {
          width: 97px;
          height: 97px;
        }

        .info {
          display: flex;
          flex-direction: column;
          width: calc(100% - 770px);
          margin-left: 10px;

          .line {
            display: flex;
            width: 100%;

            .item {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: flex;
              align-items: center;
              width: 33.3%;
              margin: 5px 0;
              color: #01EEFD;
              font-size: 16px;

              .el-input,
              .el-select,
              .el-date-picker {
                width: 180px;
              }
            }

            .item1 {
              display: flex;
              align-items: center;
              margin: 5px 0;
              margin-right: 20px;
              color: #01EEFD;
              font-size: 16px;

              .el-select {
                width: 60px;
              }
            }

          }

          .line1 {
            // justify-content: space-between;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          width: 650px;

          .table-title {
            width: 100%;
            color: #01EEFD;
            font-size: 20px;
          }

          .table-box {
            display: flex;

            /deep/.el-input__inner {
              background-color: transparent;
              text-align: center;
              border: none;
              color: #01EEFD;
            }

            .flex {
              display: flex;
              justify-content: space-evenly;
              width: 100%;

              span {
                cursor: pointer;
              }
            }

            .table {
              width: 577px;
              height: 120px;

              .thead {
                display: flex;
                width: 100%;
                height: 40px;
                border-bottom: 1px solid #01EEFD;
                box-sizing: border-box;

                .input {
                  width: 100%;
                  height: 40px;
                  line-height: 40px;
                  background-color: transparent;
                  text-align: center;
                  border: none;
                  color: #01EEFD;
                }

                /deep/.el-input__inner {
                  background-color: transparent;
                  text-align: center;
                  border: none;
                  color: #01EEFD;
                }

                .td {
                  flex: 1;
                  height: 40px;
                  line-height: 40px;
                  text-align: center;
                  border-right: 1px solid #01EEFD;
                  color: #01EEFD;
                  font-size: 16px;
                  box-sizing: border-box;
                }

                .td2 {
                  flex: 2;
                }

                .td3 {
                  display: flex;
                  justify-content: space-evenly;

                  span {
                    cursor: pointer;
                  }
                }
              }

              .thead1 {
                border-bottom: none;
              }

              .tscroll {
                overflow-y: scroll;
                width: 100%;
                height: 80px;

                &::-webkit-scrollbar {
                  width: 0;
                }
              }
            }

            .add {
              width: 30px;
              height: 30px;
              line-height: 30px;
              margin-left: 10px;
              text-align: center;
              background-color: #04B4D0;
              border-radius: 50%;
              color: #fff;
              font-size: 16px;
              cursor: pointer;
            }
          }

        }
      }

      .list {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-top: 1px solid #01EEFD;
        color: #01EEFD;
        font-size: 16px;
        box-sizing: border-box;

        .span {
          margin-left: 36px;
        }

        .item {
          display: flex;
          align-items: center;
          color: #01EEFD;
          font-size: 16px;

          .key {
            white-space: nowrap;
            margin-right: 10px;
          }

          .input {
            width: 180px;
          }
        }

        .item1 {
          position: relative;
          width: 50%;

          .add {
            position: absolute;
            top: 0;
            right: 30px;
            width: 100px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin: 10px auto 0;
            padding: 0;
            border-radius: 8px;
            border: none;
            background-color: #1E58A6;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }

      .list1 {
        justify-content: space-between;
        padding: 10px 20px;
      }

      .list2 {
        border-top: none;
      }

      .context {
        display: flex;
        width: 100%;

        .table {
          width: 50%;
          height: 200px;

          /deep/.el-input__inner {
            background-color: transparent;
            text-align: center;
            border: none;
            color: #01EEFD;
          }

          .flex {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            span {
              cursor: pointer;
            }
          }

          .thead {
            display: flex;
            height: 40px;
            border-bottom: 1px solid #01EEFD;
            box-sizing: border-box;

            .td {
              flex: 1;
              height: 40px;
              line-height: 40px;
              text-align: center;
              color: #01EEFD;
              font-size: 16px;
              border-right: 1px solid #01EEFD;
              box-sizing: border-box;
            }

            .flex {
              display: flex;
              justify-content: space-evenly;
              border-right: none;

              span {
                cursor: pointer;
              }
            }

            .border {
              border-right: none;
            }
          }

          .thead1 {
            background-color: #024276;
            border-bottom: none;

            .td {
              border-right: none;
            }
          }

          .thead2 {
            border-bottom: none;
          }

          .tscroll {
            overflow-y: scroll;
            width: 100%;
            height: calc(100% - 40px);

            &::-webkit-scrollbar {
              width: 0;
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 50%;
          height: 200px;

          .info-line {
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 20px;
            box-sizing: border-box;
          }

          .item {
            display: flex;
            align-items: center;

            .key {
              margin-right: 10px;
              white-space: nowrap;
              color: #01EEFD;
              font-size: 16px;
            }

            .input {
              width: 180px;
            }
          }

          .item1 {
            margin-left: 20px;
          }
        }
      }

      .submit {
        width: 138px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin: 10px auto 0;
        padding: 0;
        border-radius: 8px;
        border: none;
        background-color: #1E58A6;
        color: #fff;
        font-size: 18px;
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  ::v-deep .el-table__empty-text {
    color: #01EEFD;
  }

}</style>

<template>
  <div class="page">
    <div class="page-left">
      <div class="zhylContent-left">
        <div v-if="isCollapse" class="zhylContent-left-collapse">
          <div class="zhylContent-left-collapse-top" @click="isCollapse = !isCollapse">
            展开<i class="el-icon-caret-bottom" style="cursor:pointer;"></i>
          </div>
          <div class="zhylContent-left-collapse-bot">
            <i style="cursor:pointer;" class="el-icon-caret-top" @click="pre"></i>
            <i style="cursor:pointer;margin: 20px 0;" class="el-icon-refresh-right" @click="refresh"></i>
            <i style="cursor:pointer;" class="el-icon-caret-bottom" @click="next"></i>
          </div>
        </div>
        <div v-if="!isCollapse" class="zhylContent-left-uncollapse">
          <div class="scroll">
            <el-menu :collapse-transition="false" :default-active="activePath" class="el-menu-vertical-demo" router
              background-color="#0E487D" text-color="#fff" active-text-color="#04B4D0" unique-opened>
              <el-menu-item index="/runkanban" @click="saveNavState('/runkanban')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>管理看板</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/monitoring">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>实时监护</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/monitor" @click="saveNavState('/monitor')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>智能监测</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/monitordata" @click="saveNavState('/monitordata')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>监测数据</span>
                </template>
              </el-menu-item>
              <el-submenu index="jtcw">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>家庭床位</span>
                </template>
                <el-menu-item index="/monitorkanban" @click="saveNavState('/monitorkanban')">
                  <template slot="title">
                    <span>监测看板</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/service" @click="saveNavState('/service')">
                  <template slot="title">
                    <span>上门服务</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-submenu index="hlfw">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>护理服务</span>
                </template>
                <el-menu-item index="/nursing" @click="saveNavState('/nursing')">
                  <template slot="title">
                    <span>护理计划</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/medication" @click="saveNavState('/medication')">
                  <template slot="title">
                    <span>用药计划</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/recovery" @click="saveNavState('/recovery')">
                  <template slot="title">
                    <span>康复计划</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="/reviewRun" @click="saveNavState('/reviewRun')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>评估管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/healthservices" @click="saveNavState('/healthservices')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>健康服务</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/healthedu" @click="saveNavState('/healthedu')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>健康教育</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/devicetube" @click="saveNavState('/devicetube')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>设备管理</span>
                </template>
              </el-menu-item>
              <el-submenu index="xtgl">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>系统管理</span>
                </template>
                <el-menu-item index="/organsetup" @click="saveNavState('/organsetup')">
                  <template slot="title">
                    <span>机构设置</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/knowledgebase" @click="saveNavState('/knowledgebase')">
                  <template slot="title">
                    <span>知识库</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/nursingdictionary" @click="saveNavState('/nursingdictionary')">
                  <template slot="title">
                    <span>护理字典</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/personnelrun" @click="saveNavState('/personnelrun')">
                  <template slot="title">
                    <span>人员管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/rolerun" @click="saveNavState('/rolerun')">
                  <template slot="title">
                    <span>角色管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/menurun" @click="saveNavState('/menurun')">
                  <template slot="title">
                    <span>菜单管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/departmentrun" @click="saveNavState('/departmentrun')">
                  <template slot="title">
                    <span>部门管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/postrun" @click="saveNavState('/postrun')">
                  <template slot="title">
                    <span>职务管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/meDepartment" @click="saveNavState('/meDepartment')">
                  <template slot="title">
                    <span>我的部门</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/testsetmeal" @click="saveNavState('/testsetmeal')">
                  <template slot="title">
                    <span>体检套餐</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-submenu index="/ycyl">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>远程医疗</span>
                </template>
                <el-menu-item index="/bultrasonic" @click="saveNavState('/bultrasonic')">
                  <template slot="title">
                    <span>远程B超</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/ecg" @click="saveNavState('/ecg')">
                  <template slot="title">
                    <span>远程心电</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/image" @click="saveNavState('/image')">
                  <template slot="title">
                    <span>远程影像</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="/consultation" @click="saveNavState('/consultation')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>会诊</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/referral" @click="saveNavState('/referral')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>转诊</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/ncd" @click="saveNavState('/ncd')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>智慧慢病</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/dictionary" @click="saveNavState('/dictionary')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>护理字典维护</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/knowledgebase" @click="saveNavState('/knowledgebase')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>知识库维护</span>
                </template>
              </el-menu-item>
              <el-submenu index="yygl">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>运营管理</span>
                </template>
                <el-menu-item index="/drugrun" @click="saveNavState('/drugrun')">
                  <template slot="title">
                    <span>药品管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/diseaserun" @click="saveNavState('/diseaserun')">
                  <template slot="title">
                    <span>疾病管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/reciperun" @click="saveNavState('/reciperun')">
                  <template slot="title">
                    <span>处方管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/roomrun" @click="saveNavState('/roomrun')">
                  <template slot="title">
                    <span>科室管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/doctorrun" @click="saveNavState('/doctorrun')">
                  <template slot="title">
                    <span>医生管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/hospitalrun" @click="saveNavState('/hospitalrun')">
                  <template slot="title">
                    <span>医院管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/listrun" @click="saveNavState('/listrun')">
                  <template slot="title">
                    <span>订单管理</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-submenu index="jkgy">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>健康干预</span>
                </template>
                <el-menu-item index="/emr" @click="saveNavState('/emr')">
                  <template slot="title">
                    <span>电子病历</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/doctoradvice" @click="saveNavState('/doctoradvice')">
                  <template slot="title">
                    <span>医嘱</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/404" >
                  <template slot="title">
                    <span>用药</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/exercisediet" @click="saveNavState('/exercisediet')">
                  <template slot="title">
                    <span>运动与饮食建议</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/patientmanage" @click="saveNavState('/patientmanage')">
                  <template slot="title">
                    <span>患者管理</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="/healthwarning" @click="saveNavState('/healthwarning')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>健康预警</span>
                </template>
              </el-menu-item>
              <el-submenu index="ymgl">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>疫苗管理</span>
                </template>
                <el-menu-item index="/dataoverview" @click="saveNavState('/dataoverview')">
                  <template slot="title">
                    <span>数据概述</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/vaccinumrun" @click="saveNavState('/vaccinumrun')">
                  <template slot="title">
                    <span>疫苗管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/userrun" @click="saveNavState('/userrun')">
                  <template slot="title">
                    <span>用户管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/essayrun" @click="saveNavState('/essayrun')">
                  <template slot="title">
                    <span>文章管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/othersettings" @click="saveNavState('/othersettings')">
                  <template slot="title">
                    <span>其他设置</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-submenu index="/jq">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>家签</span>
                </template>
                <el-menu-item index="/signingrun" @click="saveNavState('/signingrun')">
                  <template slot="title">
                    <span>签约管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/servicerun" @click="saveNavState('/servicerun')">
                  <template slot="title">
                    <span>服务管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/residentrun" @click="saveNavState('/residentrun')">
                  <template slot="title">
                    <span>居民管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/newsrun" @click="saveNavState('/newsrun')">
                  <template slot="title">
                    <span>消息管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/datamanagement" @click="saveNavState('/datamanagement')">
                  <template slot="title">
                    <span>资料管理</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/datastatistics" @click="saveNavState('/datastatistics')">
                  <template slot="title">
                    <span>数据统计</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/systemmanagement" @click="saveNavState('/systemmanagement')">
                  <template slot="title">
                    <span>系统设置</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="/bookingrun" @click="saveNavState('/bookingrun')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>预约管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartconsultation" @click="saveNavState('/smartconsultation')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>智慧问诊</span>
                </template>
              </el-menu-item>
            </el-menu>
          </div>
          <!-- <div class="zhylContent-left-uncollapse-bot" @click="isCollapse = !isCollapse">
            收回<i style="cursor:pointer;" class="el-icon-caret-top"></i>
          </div> -->
        </div>
      </div>
    </div>
    <div class="page-right">
      <div class="capsule">
        <div class="capsule-item">
          <div class="title">
            <div class="top"></div>
            <div class="bottom">
              <div class="text">
                <span>服务记录</span>
              </div>
            </div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <div class="table">
            <div class="table-title">
              <div class="left"></div>
              <div class="center">
                <span>疫苗服务</span>
              </div>
              <div class="right"></div>
            </div>
            <div class="table-box">
              <div class="position">
                <div class="button">
                  <span>更多</span>
                </div>
              </div>
              <div class="tr">
                <span class="td flex1">医生姓名</span>
                <span class="td flex3">服务时间</span>
                <span class="td">接种地点</span>
                <span class="td flex1">操作</span>
              </div>
              <div class="scroll">
                <div class="tr" v-for="(item, index) in 14" :key="index">
                  <span class="td flex1">李明明</span>
                  <span class="td flex3">2021-12-25 12:00:00</span>
                  <span class="td">古城营镇卫生院</span>
                  <span class="td flex flex1">
                    <span class="button">查看</span>
                    <span class="button">删除</span>
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="capsule-item">
          <div class="table">
            <div class="table-title">
              <div class="left"></div>
              <div class="center">
                <span>疫苗服务</span>
              </div>
              <div class="right"></div>
            </div>
            <div class="table-box">
              <div class="position">
                <div class="button">
                  <span>更多</span>
                </div>
              </div>
              <div class="tr">
                <span class="td flex1">医生姓名</span>
                <span class="td flex3">服务时间</span>
                <span class="td">接种地点</span>
                <span class="td flex1">操作</span>
              </div>
              <div class="scroll">
                <div class="tr" v-for="(item, index) in 14" :key="index">
                  <span class="td flex1">李明明</span>
                  <span class="td flex3">2021-12-25 12:00:00</span>
                  <span class="td">古城营镇卫生院</span>
                  <span class="td flex flex1">
                    <span class="button">查看</span>
                    <span class="button">删除</span>
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="capsule-item">
          <div class="table">
            <div class="table-title">
              <div class="left"></div>
              <div class="center">
                <span>诊疗服务</span>
              </div>
              <div class="right"></div>
            </div>
            <div class="table-box">
              <div class="position">
                <div class="button">
                  <span>更多</span>
                </div>
              </div>
              <div class="tr">
                <span class="td flex1">医生姓名</span>
                <span class="td flex3">服务时间</span>
                <span class="td">接种地点</span>
                <span class="td">服务方式</span>
              </div>
              <div class="scroll">
                <div class="tr" v-for="(item, index) in 14" :key="index">
                  <span class="td flex1">李明明</span>
                  <span class="td flex3">2021-12-25 12:00:00</span>
                  <span class="td">古城营镇卫生院</span>
                  <span class="td flex">
                    <span class="button">问诊</span>
                    <span class="button">处方</span>
                    <span class="button">视频</span>
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="capsule">
        <div class="capsule-item capsule-item1">
          <div class="title">
            <div class="top"></div>
            <div class="bottom">
              <div class="text">
                <span>就诊记录</span>
              </div>
            </div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <div class="table">
            <div class="table-title">
              <div class="left"></div>
              <div class="center">
                <span>在线问诊</span>
              </div>
              <div class="right"></div>
            </div>
            <div class="table-box">
              <div class="position">
                <div class="button">
                  <span>更多</span>
                </div>
              </div>
              <div class="tr">
                <span class="td flex1">患者姓名</span>
                <span class="td flex1">性别</span>
                <span class="td flex1">年龄</span>
                <span class="td flex3">问诊时间</span>
                <span class="td">问诊地点</span>
                <span class="td flex1">操作</span>
              </div>
              <div class="scroll">
                <div class="tr" v-for="(item, index) in 14" :key="index">
                  <span class="td flex1">李明明</span>
                  <span class="td flex1">男</span>
                  <span class="td flex1">66</span>
                  <span class="td flex3">2021-12-25 12:00:00</span>
                  <span class="td">古城营镇卫生院</span>
                  <span class="td flex1">
                    <span class="button">删除</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="capsule-item capsule-item1">
          <div class="table">
            <div class="table-title">
              <div class="left"></div>
              <div class="center">
                <span>在线问诊</span>
              </div>
              <div class="right"></div>
            </div>
            <div class="table-box">
              <div class="position">
                <div class="button">
                  <span>更多</span>
                </div>
              </div>
              <div class="tr">
                <span class="td flex1">患者姓名</span>
                <span class="td flex1">性别</span>
                <span class="td flex1">年龄</span>
                <span class="td flex3">复诊时间</span>
                <span class="td">购药地点</span>
                <span class="td">药品名称</span>
                <span class="td flex1">操作</span>
              </div>
              <div class="scroll">
                <div class="tr" v-for="(item, index) in 14" :key="index">
                  <span class="td flex1">李明明</span>
                  <span class="td flex1">男</span>
                  <span class="td flex1">66</span>
                  <span class="td flex3">2021-12-25 12:00:00</span>
                  <span class="td">古城营镇卫生院</span>
                  <span class="td">阿莫西林、阿莫西林</span>
                  <span class="td flex1">
                    <span class="button">删除</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="capsule capsule1">
        <div class="title">
          <div class="top"></div>
          <div class="bottom">
            <div class="text">
              <span>就诊记录</span>
            </div>
          </div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <div class="tab">
          <div class="tab-item tab-active">
            <span>卫生院</span>
          </div>
          <div class="tab-item">
            <span>医生</span>
          </div>
        </div>
        <div class="chart" id="charts"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: '/runkanban'
    }
  },
  mounted () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.$nextTick(() => {
      this.canvasCharts()
    })
  },
  methods: {
    saveNavState (path) {
      window.sessionStorage.setItem('activePath', path)
      this.activePath = path
    },
    addColor (color1, color2) {
      return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: color1
      }, {
        offset: 1,
        color: color2
      }])
    },

    canvasCharts () {
      this.chartDom = document.getElementById('charts')
      var myChart = this.$echarts.init(this.chartDom)
      var option
      var colors = []
      colors.push(this.addColor('#F7903C', '#423635'))
      colors.push(this.addColor('#02DFF1', '#074261'))
      colors.push(this.addColor('#3CF787', '#074261'))
      const axisLabel = {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 13
        }
      }
      const series = []
      const seriesTitles = ['小孩', '妇女', '老年人']
      seriesTitles.forEach(item => {
        series.push(
          {
            type: 'bar',
            name: item,
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: 10
                  }
                }
              }
            },
            barWidth: 18
          }
        )
      })
      option = {
        color: colors,
        legend: {
          show: true,
          data: seriesTitles,
          x: 'right',
          textStyle: {
            color: '#ffffff'
          }
        },
        tooltip: {},
        dataset: {
          source: [
            ['白家庄村卫生室', 43.3, 85.8, 93.7, 58],
            ['东阳台村卫生室', 83.1, 73.4, 55.1, 42],
            ['西安庄四村卫生室', 86.4, 65.2, 82.5, 33],
            ['东安庄一村卫生室', 72.4, 53.9, 39.1, 22],
            ['大魏村卫生室', 72.4, 53.9, 39.1, 22],
            ['西安庄一村卫生室', 72.4, 53.9, 39.1, 22],
            ['小魏村卫生室', 72.4, 53.9, 39.1, 22]
          ]
        },
        xAxis: {
          type: 'category',
          axisLabel,
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: 'mmol/L',
          nameTextStyle: {
            color: '#ffffff',
            nameLocation: 'start'
          },
          axisLabel,

          axisLine: {
            show: true,
            lineStyle: {
              color: ['#447DC3']
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#447DC3']
            }
          }
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            show: false, // 是否显示滑动条，不影响使用
            type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 6 // 一次性展示多少个。
          }
        ],
        series
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: calc(100% - 80px);
  background-color: #071224;

  .page-left {
    width: 120px;
    height: 100%;

    .el-menu {
      border: none;
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 107px);
    height: 100%;
    margin-left: 7px;
    padding: 30px;
    background-color: #081C38;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .capsule {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 386px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #016DBD;
    border-radius: 50px;
    background: #0A2F56;
    box-shadow: inset 0px 0px 13px 1px #0174C8;

    .capsule-item {
      display: flex;
      flex-direction: column;
      width: 556px;
    }

    .capsule-item1 {
      width: calc(50% - 20px);
    }

    .title {
      position: relative;
      width: 159px;
      height: 35px;

      .top {
        width: 72px;
        margin-left: 4px;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 6px solid #03BCD7;
      }

      .bottom {
        position: relative;
        width: 121px;
        height: 30px;
        line-height: 30px;
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        transform: skewX(20deg);
        color: #fff;
        font-size: 16px;
        font-weight: bold;

        &::before {
          position: absolute;
          top: -7px;
          left: -8px;
          content: '';
          width: 0;
          height: 0;
          border-color: transparent transparent #0A2F56 transparent;
          border-width: 6px;
          border-style: solid;
          transform: rotate(-35deg);
        }

        .text {
          width: 100%;
          text-align: center;
          transform: skewX(-20deg);
        }
      }

      .line {
        position: absolute;
        bottom: -2px;
        right: 10px;
        width: 2px;
        height: 35px;
        background-color: #032142;
        transform: rotate(-30deg);
      }

      .line:last-child {
        right: 0;
      }
    }

    .tab {
      position: absolute;
      top: 30px;
      left: 200px;
      display: flex;
      align-items: center;

      .tab-item {
        margin-right: 20px;
        padding: 2px 19px;
        background-color: #7C7C7C;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .tab-active {
        background-color: #04B4D0;
      }
    }

    .table {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;

      .table-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          position: relative;
          width: 40px;
          height: 32px;
          margin-left: 3px;
          background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
          border: 1px solid #017CDB;
          border-bottom-color: transparent;
          transform: skewX(-10deg);
          box-sizing: border-box;
        }

        .center {
          width: 263px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
          border: 1px solid #017CDB;
          border-bottom-color: transparent;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;
        }

        .right {
          position: relative;
          width: 40px;
          height: 32px;
          margin-right: 3px;
          background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
          border: 1px solid #017CDB;
          border-bottom-color: transparent;
          transform: skewX(10deg);
          box-sizing: border-box;
        }
      }

      .table-box {
        position: relative;
        width: 100%;
        height: 270px;
        margin-top: -1px;
        padding-top: 32px;
        border: 1px solid #017CDB;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-sizing: border-box;

        .position {
          position: absolute;
          top: 2px;
          right: 10px;
          width: 77px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background-color: #03BCD7;
          transform: skewX(-20deg);
          color: #fff;
          font-size: 20px;

          &::after {
            position: absolute;
            top: 0;
            right: -5px;
            z-index: 88;
            content: '';
            width: 10px;
            height: 29px;
            background-color: #03BCD7;

            transform: skewX(20deg);
          }

          .button {
            transform: skewX(20deg);
          }
        }

        .scroll {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 40px);

          &::-webkit-scrollbar {
            width: 0;
          }
        }

        .tr {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          padding: 0 10px;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 12px;

          .td {
            flex: 2;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .flex {
            display: flex;
            justify-content: space-between;
          }

          .flex1 {
            flex: 1;
          }

          .flex3 {
            flex: 3;
          }

          .button {
            cursor: pointer;
          }
        }
      }
    }

    .chart {
      width: 100%;
      height: calc(100% - 40px);
    }
  }

  .capsule1 {
    align-items: flex-start;
    flex-direction: column;
  }
}
</style>

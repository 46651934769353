<template>
  <div class="write">
    <div class="title">
      <span>下级机构</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="head"></div>
        <div class="box-title">
          <p>机构名称</p>
          <p>服务人数</p>
          <p>联系电话</p>
          <p>负责人</p>
        </div>
        <div class="scroll">
          <div v-for="(item, index) in tableData" :key="index" class="list">
            <p>{{ item.jddw }}</p>
            <p>{{ item.service }}</p>
            <p>{{ item.phone }}</p>
            <p>{{ item.run }}</p>
          </div>
        </div>
        <el-pagination v-if="showPagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageParams.start" background
           :page-size="pageParams.length" layout="total, prev, pager, next, jumper" :total="pageParams.total" size="mini">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      pageParams: {
        start: 1,
        length: 10,
        sxtj: '',
        total: 0
      },
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      currentPage: 0,
      total: 400,
      tableData: []
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const start = this.pageParams.start === 1 ? '0' : (this.pageParams.start - 1) * 10 + ''
      const params = {
        jgbh: this.loginInfo.jgbh || '',
        ssjgbh: this.loginInfo.jgbh || '',
        start: start,
        jgjb: 5,
        length: this.pageParams.length + ''
      }
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-122245615
      const { data: res } = await this.$http.get('/api/jkct/findjglist', { params: params })
      console.log('信息res', res)
      if (res.code !== 0) return this.$message.error(res.msg || res.message)
      this.tableData = res.data
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 12.6625rem;
    height: 6.825rem;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .275rem;
      right: .3rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .box {
      width: 860px;
      height: 283px;
      margin: 0 auto;
      background-color: #072E44;
    }

    .head {
      width: 140px;
      height: 30px;
      background: url(../img/subordinates.png);
      background-size: cover;
      margin: 0 auto 7px;
    }

    .box-title {
      display: flex;
      align-items: center;
      width: 846px;
      height: 40px;
      border-top: 1px solid #01EEFD;
      border-bottom: 1px solid #01EEFD;
      color: #01EEFD;
      font-size: 16px;
      box-sizing: border-box;

      p {
        flex: 1;
        text-align: center;
      }
    }

    .scroll {
      overflow: scroll-y;
      width: 846px;
      height: 207px;

      .list {
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;
        color: #fff;
        font-size: 16px;

        p {
          flex: 1;
          text-align: center;
          padding: 0 5px;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .list:nth-child(even) {
        background-color: #084364;
      }
    }

    .item {
      display: flex;
      align-items: center;
      margin-top: 20px;
      color: #01EEFD;
      font-size: .225rem;

      .span {
        width: 1rem;
        margin-right: .125rem;
      }

      .el-input,
      /deep/.el-input__inner {
        width: 2.1rem;
        height: .475rem;
      }

      /deep/.el-input__icon {
        line-height: .475rem;
      }

      .textarea,
      /deep/.el-textarea__inner {
        width: calc(100% - 1rem);
        height: .8375rem;
        resize: none;
        border: none;
      }
    }

    .item1 {
      width: 100%;
      align-items: flex-start;
    }
  }
}
</style>

<template>
  <div class="son">
    <div class="son-top">
      <div class="son-box">
        <div class="son-line">
          <div class="son-text">
            <span>初步诊断：上呼吸道感染 </span>
          </div>
          <el-select size="small" v-model="value" placeholder="请选择诊断">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="son-tab">
          <span v-for="(item, index) in tabs" :key="index" class="son-item" :class="{ 'son-active': tabIndex === index }"
            @click="tabClick(index)">{{ item }}</span>
        </div> -->
        <div class="tag">
          <el-tag v-for="(tag, index) in tags" :key="tag.name" :closable="tag.name !== '添加处方'"
            :class="{ 'tab1': tag.name === '添加处方', 'tab2': tag.name === '处方单1', 'tab3': index === tags.length - 1 }"
            :type="tag.type" size="small" @close="handleClose(tag)">
            {{ tag.name }}
          </el-tag>
        </div>
        <div class="son-line">
          <div class="son-tip">
            <div class="son-tip-left">
              <span>单号：NO.+WM202111120001</span>
              <span>创建时间：2021-11-12 16:00:57</span>
              <span>合计：2种</span>
              <span>金额：36.00</span>
              <span>收费状态：未收费</span>
            </div>
            <div class="son-tip-right">
              <span class="el-icon-plus"></span>
            </div>
          </div>
        </div>
        <el-table :data="tableData" max-height="190" border style="width: 100%">
          <el-table-column type="selection" width="55" label="组号">
          </el-table-column>
          <el-table-column prop="date" label="项目分类" >
            <template>
              <el-select v-model="value" placeholder="">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="项目名称" >
            <template>
              <el-select v-model="value" placeholder="">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="单价" >
            <template>
              <div class="flex">
                <el-input></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="次数" >
            <template>
              <div class="flex">
                <el-input></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="天数" >
            <template>
              <div class="flex">
                <el-input></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="总金额" >
            <template>
              <div class="flex">
                <el-input></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="折扣" >
            <template>
              <div class="flex">
                <el-input></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="折后价" >
            <template>
              <div class="flex">
                <el-input></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <div class="flex">
              <span>编辑</span>
              <span>删除</span>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="son-button">
      <el-button size="mini" @click="openCallMedicalRecords">调用处方</el-button>
      <el-button size="mini">存为模板</el-button>
      <el-button size="mini">打印</el-button>
      <el-button size="mini">保存</el-button>
      <el-button size="mini" @click="openCharge">收费</el-button>
      <el-button size="mini" class="gray" @click="openEndVisit">结束就诊</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['西药处方', '中草药处方']),
      tags: [
        { name: '添加处方', type: '' },
        { name: '处方单1', type: '' },
        { name: '处方单2', type: '' }
      ],
      tableData: [
        { name: '111' },
        { name: '111' },
        { name: '111' }
      ],
      options: [{
        value: '1',
        label: '1'
      }],
      value: ''
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    handleClose (tag) {
      console.log(tag)
    },
    openCallMedicalRecords () {
      this.$emit('openCallMedicalRecords')
    },
    openCharge () {
      this.$emit('openCharge')
    },
    openEndVisit () {
      this.$emit('openEndVisit')
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;

  .son-top {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-box {
      display: flex;
      flex-direction: column;
      width: 100%;

      .son-line {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 5px 0;
        padding-left: 10px;
        box-sizing: border-box;

        .son-text {
          margin-right: 10px;
          color: #01EEFD;
          font-size: 14px;
        }

        .son-tip {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .son-tip-left {
            color: #fff;
            font-size: 14px;

            span {
              margin-right: 10px;
            }
          }

          .son-tip-right {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #04B4D0;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
          }

        }
      }

      .son-tab {
        display: flex;
        align-items: center;
        height: 34px;
        padding-left: 10px;
        box-sizing: border-box;

        .son-item {
          height: 34px;
          line-height: 34px;
          margin-right: 40px;
          border-bottom: 1px transparent solid;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }

        .son-active {
          color: #01EEFD;
          border-bottom: 1px #01EEFD solid;
        }
      }

      .tag {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 10px 0;
        padding-left: 10px;
        box-sizing: border-box;

        .el-tag {
          margin-right: 10px;
        }

        .tab1 {
          background-color: #00D0E0;
          border: none;
          color: #fff;
        }

        .tab2 {
          background-color: #fff;
          border: none;
          color: #00D0E0;
        }

        .tab3 {
          background-color: #7B8081;
          border: none;
          color: #fff;
        }

        /deep/.el-tag .el-tag__close {
          color: red;
        }

      }

      // 表格样式修改
      .el-table {
        background-color: #081C38;
        border: 1px solid #fff;
        box-sizing: border-box;

        &::before {
          background-color: transparent;
        }

        // 表头首行
        /deep/th.el-table__cell.is-leaf {
          border-bottom: none;
        }

        /deep/td.el-table__cell {
          border-bottom: 1px solid #01EEFD;
        }

        // 表头背景颜色
        /deep/th.el-table__cell {
          background-color: #fff;
        }

        /deep/.el-table__header .el-table__cell {
          color: #333333;
        }

        /deep/.el-table__cell {
          text-align: center;
          color: #fff;
        }

        /deep/tr {
          background-color: transparent;
        }

        /deep/.el-table__body tr:hover>td.el-table__cell {
          background-color: transparent !important;
        }

        /deep/.el-checkbox__inner {
          border: 1px solid gray;
          background-color: transparent;
        }

        /deep/.is-indeterminate .el-checkbox__inner,
        /deep/.is-checked .el-checkbox__inner {
          background-color: #04B4D0;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar {
          // width: 0;
        }

        /deep/.el-table__empty-text {
          color: #fff;
        }

        /deep/.el-input__inner {
          background-color: transparent;
          color: #fff;
        }

        /deep/.hover-row {
          background-color: transparent;
        }

        /deep/ .el-table tbody tr:hover>td {
          background-color: transparent;
        }

        /deep/.el-table__body tr.hover-row>td.el-table__cell {
          background-color: transparent;
        }

        /deep/.el-table__fixed-right {
          background-color: #081C38;
        }

        /deep/.el-table__fixed-right::before {
          background-color: transparent;
        }

        .flex {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;

          .text {
            margin-left: 4px;
            white-space: nowrap;
          }

          .item {
            font-size: 14px;
            cursor: pointer;
          }

          .green {
            color: #17AF12;
          }

          .red {
            color: #FF6600;
          }

          .organ {
            color: #FF0000;
          }

          .icon {
            color: #0B75EF;
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .son-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;

    .el-button {
      margin: 0 25px;
      background-color: #1E58A6;
      color: #fff;
      border: none;
    }

    .gray {
      background-color: #909090;
    }
  }

}
</style>

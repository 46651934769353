<!--  -->
<template>
  <div class='com-container com-container1'>
    <div class="com-title">
      <div class="com-title-left" style="color:#fff;">心率趋势图</div>
    </div>
    <div class="com-chart com-chart1" ref="ref_rank"></div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import { getExpectDate } from '@/utils/utils'
export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  props: ['allData'],
  data () {
  // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    allData (v) {
      // // console.log('allData -------------', v)
      this.datas = v
      this.initChart()
      // getExpectDate('2022-12-14', 'day', -1)
    }
  },
  // 方法集合
  methods: {
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank, 'chalk')
      const initOption = {
        title: {
          textStyle: {
            align: 'center',
            color: '#fff',
            fontSize: 20
          },
          top: '5%',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(0, 255, 233,0)'
                }, {
                  offset: 0.5,
                  color: 'rgba(255, 255, 255,1)'
                }, {
                  offset: 1,
                  color: 'rgba(0, 255, 233,0)'
                }],
                global: false
              }
            }
          }
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '15%'
        // containLabel: true
        },
        xAxis: [{
          type: 'category',
          axisLine: {
            show: true
          },
          splitArea: {
            // show: true,
            color: '#f00',
            lineStyle: {
              color: '#f00'
            }
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false
          },
          boundaryGap: false,
          data: [getExpectDate('2022-12-14', 'day', '-6'), getExpectDate('2022-12-14', 'day', '-5'), getExpectDate('2022-12-14', 'day', '-4'),
            getExpectDate('2022-12-14', 'day', '-3'), getExpectDate('2022-12-14', 'day', '-2'), getExpectDate('2022-12-14', 'day', '-1'), '2022-12-14']

        }],

        yAxis: [{
          type: 'value',
          min: 0,
          // max: 140,
          splitNumber: 4,
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.1)'
            }
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            show: true,
            margin: 20,
            textStyle: {
              color: '#d1e6eb'

            }
          },
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '心率趋势',
          type: 'line',
          smooth: false, // 是否平滑
          showAllSymbol: true,
          // symbol: 'image://./static/images/guang-circle.png',
          symbol: 'circle',
          symbolSize: 15,
          lineStyle: {
            color: '#00b3f4',
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 5,
            shadowOffsetX: 5
          },
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#00b3f4'
            }
          },
          itemStyle: {
            color: '#00b3f4',
            borderColor: '#fff',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 2,
            shadowOffsetX: 2
          },
          tooltip: {
            show: false
          },
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(0,179,244,0.3)'
            },
            {
              offset: 1,
              color: 'rgba(0,179,244,0)'
            }
            ], false),
            shadowColor: 'rgba(0,179,244, 0.9)',
            shadowBlur: 20
          },
          data: [65, 70, 68, 71, 69, 66, 69]
        }
        ]
      }
      this.chartInstance.setOption(initOption)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.com-title-left {
  width: 530px !important;
  height: 40px;
}

.com-chart1 {
  height: calc(100% - 50px) !important;

  div {
    height: 100% !important;
  }
}
</style>

<template>
  <div class="write" :class="{ write1: showWrite }">
    <div class="title">
      <span>用药计划</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="titles">
          <span>基本信息</span>
        </div>
        <div class="info">
          <div class="img"></div>
          <div class="news">
            <span class="text">王志 男 40岁 优享会员</span>
            <span class="text">13684236986</span>
            <span class="text">610110200001021123</span>
            <span class="text">高血压 糖尿病</span>
          </div>
          <div class="news">
            <span class="text">床位信息：3号楼-302房-01床</span>
            <span class="text">入院时间：2022-11-01</span>
            <span class="text">用户来源：社区居民</span>
            <span class="text">备注信息：</span>
          </div>
          <div class="news">
            <span class="text">病历号：p202111050001</span>
            <span class="text">就诊次数：46</span>
            <span class="text">就诊时间：2011-11-11</span>
            <span class="text">科室/病区：放射科</span>
          </div>
        </div>
        <div class="tip">
          <span>2021-11-18 （进）注射用头孢曲松钠 罗氏芬（（进）注射用头孢曲松钠 罗氏芬） 1g 皮试结果:阴性</span>
        </div>
        <div class="tab">
          <span class="item" v-for="(item, index) in tabs" :key="index" :class="{ active: tabIndex === index }"
            @click="tabClick(index)">{{ item }}</span>
        </div>
        <div class="context">
          <!-- 病历 -->
          <Tab1 v-if="tabIndex === 0" @openEndVisit="openEndVisit" @openCharge="openCharge" @openCallMedicalRecords="openCallMedicalRecords"></Tab1>
          <!-- 处方 -->
          <Tab2 v-if="tabIndex === 1" @openEndVisit="openEndVisit" @openCharge="openCharge" @openCallMedicalRecords="openCallMedicalRecords"></Tab2>
          <!-- 检验检查 -->
          <Tab3 v-if="tabIndex === 2" @openEndVisit="openEndVisit" @openCharge="openCharge" @openCallMedicalRecords="openCallMedicalRecords"></Tab3>
          <!-- 治疗项目 -->
          <Tab4 v-if="tabIndex === 3" @openEndVisit="openEndVisit" @openCharge="openCharge" @openCallMedicalRecords="openCallMedicalRecords"></Tab4>
          <ecg :height="240" :width="750" :tip="true" :maxV="500"
            :ecgData="[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -240, -230, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 140, 330, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -240, -230,]" v-if="false">
          </ecg>
        </div>
      </div>
    </div>
    <!-- 调用病历 -->
    <CallMedicalRecords v-if="showCallMedicalRecords" @close="closeCallMedicalRecords"></CallMedicalRecords>
    <!-- 收费 -->
    <Charge v-if="showCharge" @close="closeCharge"></Charge>
    <!-- 结束就诊 -->
    <EndVisit v-if="showEndVisit" @close="closeEndVisit"></EndVisit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tab1 from './tab1.vue'
import Tab2 from './tab2.vue'
import Tab3 from './tab3.vue'
import Tab4 from './tab4.vue'

import ecg from '../ecg.vue'
import CallMedicalRecords from './callMedicalRecords.vue'
import Charge from './charge.vue'
import EndVisit from './endVisit.vue'
export default {
  props: {
    selectId: {
      type: String
    }
  },
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    ecg,
    CallMedicalRecords,
    Charge,
    EndVisit
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['病历', '处方', '检验检查', '治疗项目']),
      can: '',
      pan: '',
      flag: true,
      wid: 100,
      hei: 100,
      x: 0,
      y: 50,
      showWrite: false,
      showCallMedicalRecords: false,
      showCharge: false,
      showEndVisit: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    // this.can = document.getElementById('can')
    // this.start()
    setTimeout(() => {
      this.showWrite = true
    }, 1000)
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openEndVisit () {
      this.showEndVisit = true
    },
    closeEndVisit () {
      this.showEndVisit = false
    },
    openCharge () {
      this.showCharge = true
    },
    closeCharge () {
      this.showCharge = false
    },
    openCallMedicalRecords () {
      this.showCallMedicalRecords = true
    },
    closeCallMedicalRecords () {
      this.showCallMedicalRecords = false
    },
    closePopup () {
      this.$emit('close')
    }
    // start () {
    //   this.can.height = this.hei
    //   this.can.width = this.wid
    //   this.pan = this.can.getContext('2d')// 获取2D图像API接口
    //   this.pan.strokeStyle = '#08b95a'// 设置画笔颜色
    //   this.pan.lineJoin = 'round'// 设置画笔轨迹基于圆点拼接
    //   this.pan.lineWidth = 2// 设置画笔粗细
    //   this.pan.beginPath()
    //   this.pan.moveTo(this.x, this.y)
    //   this.index = setInterval(this.move, 1000)
    // },
    // move () {
    //   this.x++
    //   if (this.x < 100) {

    //   } else {
    //     if (this.x >= this.wid - 100) {

    //     } else {
    //       var z = Math.random() * 280
    //       if (this.y <= z) {
    //         this.flag = true
    //       }
    //       if ((this.hei - this.y) <= z) {
    //         this.flag = false
    //       }
    //       if (this.flag) {
    //         this.y += 5
    //       } else {
    //         this.y -= 5
    //       }
    //     }
    //   }
    //   if (this.x === this.wid) {
    //     this.pan.closePath()
    //     clearInterval(this.index)
    //     this.index = 0
    //     this.x = 0
    //     this.y = this.hei / 2
    //     this.flag = true
    //     this.start()
    //   }
    //   console.log(this.x)
    //   this.pan.lineTo(Number.parseInt(this.x), this.y)
    //   this.pan.stroke()
    // }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding: 0 80px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    max-height: 900px;
    padding: 50px;
    padding-top: 40px;
    padding-bottom: 70px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 40px;
      right: 40px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .box {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .titles {
        width: 100%;
        height: 45px;
        line-height: 45px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 20px;
        font-weight: 600;
      }

      .info {
        display: flex;
        align-items: center;
        width: 100%;
        height: 160px;
        padding-left: 10px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        .img {
          width: 128px;
          height: 139px;
          margin-right: 20px;
          background-color: #fff;
        }

        .news {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 139px;
          margin-right: 100px;

          .text {
            color: #fff;
            font-size: 18px;
          }
        }
      }

      .tip {
        width: 60%;
        height: 20px;
        line-height: 20px;
        margin: 10px 0;
        padding: 0 20px;
        padding-left: 10px;
        background-color: #FF0C0C;
        border-radius: 6px;
        box-sizing: border-box;
        color: #fff;
        font-size: 14px;
      }

      .tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 45px;
        padding-left: 10px;
        box-sizing: border-box;

        .item {
          width: 120px;
          height: 38px;
          line-height: 38px;
          margin-right: 20px;
          text-align: center;
          white-space: nowrap;
          background-color: #D5D5D5;
          color: #fff;
          font-size: 22px;
          cursor: pointer;
        }

        .active {
          background-color: #01EEFD;
        }
      }

      .context {
        width: 100%;
        height: calc(100% - 290px);
      }
    }
  }

}

.write1 {
  z-index: 2003;
}
</style>

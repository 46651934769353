<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-01-16 17:19:36
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-02-19 09:51:27
 * @FilePath: \visions\src\components\yiliao2\xltjDialog\gather.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class='xyxthz'>
    <div class="qst"><xtqst :allData="allData"></xtqst></div>
    <div class="tjt">
      <div class="tjt-item"><xttj1></xttj1></div>
      <div class="tjt-item"><xttj2></xttj2></div>
      <div class="tjt-item"><xttj3></xttj3></div>
      <div class="tjt-item"><xttj4></xttj4></div>
    </div>
  </div>
</template>
<script>
import xtqst from './xtqst.vue'
import xttj1 from './xttj1.vue'
import xttj2 from './xttj2.vue'
import xttj3 from './xttj3.vue'
import xttj4 from './xttj4.vue'
export default {
  components: { xtqst, xttj1, xttj2, xttj3, xttj4 },
  props: ['datas'],
  data () {
  // 这里存放数据
    return {
      allData: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    datas (v) {
      this.allData = v
      // // console.log(111111111, this.allData)
    }
  },
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.allData = this.datas
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.xyxthz {
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  .qst {
    width: calc(100% - 60px);
    height: 50%;
    border: 1px solid #03757F;
    border-radius: 6px;
    box-sizing: border-box;
  }
  .tjt {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 44%;
    margin-top: 10px;

    &-item {
      width: 24.5%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
      box-sizing: border-box;
    }
  }
}
</style>

<!-- 机构排名 -->
<template>
  <div class='yyfwContainer'>
    <div class="line line1">
      <div class="item">
        <span class="key">姓名</span>
        <el-input size="small"></el-input>
      </div>
      <div class="item">
        <span class="key">年龄</span>
        <el-input size="small"></el-input>
      </div>
      <div class="item">
        <span class="key">联系电话</span>
        <el-input size="small"></el-input>
      </div>
      <div class="item">
        <span class="key">编号</span>
        <el-input size="small"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>服务日期</span>
      </div>
      <div class="flex1">
        <span>2022-12-05</span>
      </div>
      <div class="line-left line-left1">
        <span>服务人</span>
      </div>
      <div class="flex1">
        <span>周文倩</span>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>服务日期</span>
      </div>
      <div class="flex1">
        <el-radio-group v-model="radio">
          <el-radio label="1">门诊</el-radio>
          <el-radio label="2">家庭</el-radio>
          <el-radio label="3">电话</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>健康状况</span>
      </div>
      <div class="flex1">
        <el-checkbox-group v-model="value">
          <el-checkbox v-for="(item, index) in list1" :key="index + ''" :label="index + ''">{{ item }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>健康状况</span>
      </div>
      <div class="flex1">
        <el-input class="width" size="small"></el-input>
        <span class="font">/</span>
        <el-input class="width" size="small"></el-input>
        <span class="font">mmHg</span>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>服务项目</span>
      </div>
      <div class="flex1">
        <el-input class="input-width" size="small"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>检测血糖</span>
      </div>
      <div class="flex1">
        <el-input class="width" size="small"></el-input>
        <span class="font">mmol/L</span>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>康复指导</span>
      </div>
      <div class="flex1">
        <el-checkbox-group v-model="value">
          <el-checkbox label="0">无 中医康复(</el-checkbox>
          <el-checkbox label="2.1">针灸</el-checkbox>
          <el-checkbox label="2.2">推拿</el-checkbox>
          <el-checkbox label="2.3">熏蒸</el-checkbox>
          <el-checkbox label="2.4">其他)</el-checkbox>
          <el-checkbox label="3">3现代医学康复(</el-checkbox>
          <el-checkbox label="3.1">物理疗法</el-checkbox>
          <el-checkbox label="3.2">运动疗法</el-checkbox>
          <el-checkbox label="3.3">作业疗法</el-checkbox>
          <el-checkbox label="3.4">语言疗法</el-checkbox>
          <el-checkbox label="3.5">其他)</el-checkbox>
          <el-checkbox label="4">4心理康复(</el-checkbox>
          <el-checkbox label="4.1">园艺疗法</el-checkbox>
          <el-checkbox label="4.2">音乐疗法</el-checkbox>
          <el-checkbox label="4.3">芳香疗法</el-checkbox>
          <el-checkbox label="4.4">舞蹈疗法</el-checkbox>
          <el-checkbox label="4.5">正念疗法</el-checkbox>
          <el-checkbox label="4.6">其他)</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>护理技能</span>
      </div>
      <div class="flex1">
        <el-checkbox-group v-model="value">
          <el-checkbox v-for="(item, index) in list2" :key="(index + 1) + ''" :label="index + ''">{{ item
          }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>保健咨询</span>
      </div>
      <div class="flex1">
        <el-checkbox-group v-model="value">
          <el-checkbox v-for="(item, index) in list3" :key="(index + 1) + ''" :label="index + ''">{{ item
          }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>营养改善指导</span>
      </div>
      <div class="flex1">
        <el-checkbox-group v-model="value">
          <el-checkbox label="0">营养需求评估 治疗饮食指导(</el-checkbox>
          <el-checkbox label="2.1">高蛋白饮食</el-checkbox>
          <el-checkbox label="2.2">糖尿病饮食</el-checkbox>
          <el-checkbox label="2.3">低蛋白饮食</el-checkbox>
          <el-checkbox label="2.4">低盐饮食</el-checkbox>
          <el-checkbox label="2.5">低脂饮食</el-checkbox>
          <el-checkbox label="2.6">低嘌呤饮食</el-checkbox>
          <el-checkbox label="2.7">低纤维饮食</el-checkbox>
          <el-checkbox label="2.8">全营养素饮食)</el-checkbox>
          <el-checkbox label="3">3饮食种类知道(</el-checkbox>
          <el-checkbox label="3.1">普食</el-checkbox>
          <el-checkbox label="3.2">软食</el-checkbox>
          <el-checkbox label="3.3">半流食</el-checkbox>
          <el-checkbox label="3.4">流食</el-checkbox>
          <el-checkbox label="3.5">其他)</el-checkbox>
          <el-checkbox label="4">其他</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>备注</span>
      </div>
      <div class="flex1">
        <el-input class="input-width" size="small"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>预约下次随访时间</span>
      </div>
      <div class="flex1">
        <el-input class="input-width" size="small"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>录入机构</span>
      </div>
      <div class="flex1">
        <el-input size="small"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>录入人</span>
      </div>
      <div class="flex1">
        <el-input size="small"></el-input>
      </div>
      <div class="line-left line-left1">
        <span>录入时间</span>
      </div>
      <div class="flex1">
        <el-date-picker size="small" type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>更新机构</span>
      </div>
      <div class="flex1">
        <el-input size="small"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="line-left">
        <span>更新人</span>
      </div>
      <div class="flex1">
        <el-input size="small"></el-input>
      </div>
      <div class="line-left line-left1">
        <span>更新时间</span>
      </div>
      <div class="flex1">
        <el-date-picker size="small" type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      radio: '1',
      value: ['1'],
      list1: Object.freeze(['无', '高血压', '糖尿病', '血脂异常', '消瘦', '贫血', '超重、肥胖', '视力异常', '其他']),
      list2: Object.freeze(['皮肤护理', '口腔护理', '管路护理', '心理护理', '其他']),
      list3: Object.freeze(['健康生活方式', '科学运动', '合理安全用药', '心理疏解', '疾病防控', '居所适老环境', '辅具使用', '老年综合症预防（跌倒等）', '预防接种', '其他'])
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.yyfwContainer {
  width: 95%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  .line {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #043762;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      height: .5rem;
      color: #01EEFD;
      font-size: .225rem;

      .key {
        margin-right: .125rem;
        white-space: nowrap;
      }

      .el-input {
        width: 120px;
      }
    }

    .line-left {
      width: 1.95rem;
      height: .5rem;
      line-height: .5rem;
      text-align: center;
      color: #01EEFD;
      font-size: .225rem;
      border-right: 1px solid #043762;
      box-sizing: border-box;
    }

    .line-left1 {
      width: 4.5375rem;
    }

    .flex1 {
      flex: 1;
      padding-left: .125rem;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: .225rem;

      .el-input {
        width: 2rem;
      }

      .font {
        margin-left: .0625rem;
        margin-right: .0625rem;
        color: #01EEFD;
      }

      .input-width {
        width: 100%;
      }
    }
  }

  .line1 {
    padding: 0 .125rem;
    justify-content: space-between;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-checkbox,
  .el-radio {
    color: #fff;
  }

  /deep/.el-checkbox__input.is-checked+.el-checkbox__label,
  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }
}
</style>

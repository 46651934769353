<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-02-15 08:53:33
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-06-06 15:26:18
 * @FilePath: \visions\src\views\zhyl\runkanban\runkanban.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="zhylContent">
    <div class="zhylContent-center">
      <div class="zhylContent-center-select">
        <div class="item">
          <el-select size="mini" v-model="rzForm.jddw" placeholder="请选择" @change="jgChangeMethod">
            <el-option v-for="item in options1" :key="item.id" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <el-button size="mini" type="primary" @click="openOrgan">下级机构<i class="el-icon-arrow-down"></i></el-button>
        </div>
        <div class="item">
          <el-button size="mini" type="primary" @click="openRank">机构排名<i class="el-icon-arrow-down"></i></el-button>
        </div>
        <div class="pagination">
          <span class="open" :class="[showPagination ? 'el-icon-s-fold' : 'el-icon-s-unfold']" @click="open"></span>
        <el-pagination v-if="showPagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageParams.start" background
           :page-size="pageParams.length" layout="total, prev, pager, next, jumper" :total="pageParams.total" size="mini">
        </el-pagination>
      </div>
      </div>
      <div class="zhylContent-center-search">
        <input type="text" placeholder="姓名/身份证号" v-model="pageParams.sxtj" @blur="search">
        <i class="el-icon-search" style="color:#fff;"></i>
      </div>
      <div class="zhylContent-center-content">
        <div class="zhylContent-center-content-box" v-for="(item, index) in dataList" :key="index">
          <div class="title">
            <span>{{ item.fwlbfjxx }}</span>
            <span>{{ item.fwlbcwxx }}</span>
          </div>
          <div class="detail">
            <div class="corner">
              <div class="item" @click="openSeeInfo(item.id)" style="cursor: pointer;">{{ item.fwdj }}</div>
              <div class="item item1" @click="openSeeInfo(item.id)" style="cursor: pointer;">{{ item.hldj }}</div>
            </div>
            <div class="detail-left">
              <div class="content-info">
                <div class="pInfo">
                  <span class="name">{{ item.name }}</span>
                  <span class="sex">
                    <i :class="item.xb == 1 ? 'iconfont icon-nan' : 'iconfont icon-nv'"></i></span>
                  <span class="age">{{ item.age }}</span>
                </div>
                <div class="rzInfo">
                  <span>入住天数:{{ item.rzts }}</span>
                  <span><i class="iconfont icon-tui icon"></i></span>
                  <div class="flex">
                    <span>入住时间:{{ item.rzsj | rzsjType }}</span>
                    <!-- <span class="font" @click="openBindPopup">绑定</span> -->
                    <el-tooltip class="item" effect="dark" content="绑定" placement="top" :enterable="false">
                      <img src="../../../assets/img/index/bind.png" class="bind" @click="openBindPopup">
                    </el-tooltip>
                  </div>
                </div>
                <div class="rzInfo1">
                  <span>入住诊断:{{ item.rzzd || '-' }}</span>
                </div>
                <!-- <div class="dnInfo">
                  <span>编号：{{ item.ylzsbh }}</span>
                  <span class="info-width">健康管家：{{ item.fwry }}</span>
                </div> -->
                <div class="dnInfo">
                  <span style="cursor: pointer;" @click="openSeeInfo(item.id)">医生：{{ item.fwzgys || '-' }}</span>
                  <span style="cursor: pointer;" @click="openSeeInfo(item.id)">护士：{{ item.fwzghs || '-' }}</span>
                  <span style="cursor: pointer;" @click="openSeeInfo(item.id)">护理员：{{ item.fwzrhly || '-' }}</span>
                </div>
                <div class="glInfo">
                  <div class="glInfo-left">
                    <i class="iconfont icon-sos"></i>
                    <i class="iconfont icon-dunpai"></i>
                    <i class="iconfont icon-gouwuche"></i>
                    <i class="iconfont icon-dasao"></i>
                    <i class="iconfont icon-chuang"></i>
                  </div>
                  <div class="glInfo-right">
                    <span class="span">
                      评<i class="iconfont icon-pinglun"></i>
                    </span>
                    <span class="span">
                      建<i class="iconfont icon-jianshen"></i>
                    </span>
                    <span class="span">
                      <el-popover placement="bottom" trigger="hover">
                        <el-button type="primary" size="mini" @click="openCarePlan">护理计划</el-button>
                        <el-button type="primary" size="mini" @click="openMedicationPlan">用药计划</el-button>
                        <span slot="reference" style="cursor:pointer">
                          <span >护</span>
                          <i class="iconfont icon-dunpai"></i>
                        </span>
                      </el-popover>
                    </span>

                    <span>
                      医<i class="iconfont icon-yisheng"></i>
                    </span>
                    <el-popover placement="bottom" trigger="hover">
                      <el-button type="primary" size="mini" @click="showYyfw = true">医养服务</el-button>
                      <el-button type="primary" size="mini">用药指导</el-button>
                      <i slot="reference" style="cursor:pointer" class="el-icon-caret-bottom"></i>
                    </el-popover>
                  </div>
                </div>
              </div>

              <div class="infos">
                <div class="infos-item" @click="(showXy = true)">
                  血压:{{ item.xueyg }}/{{ item.xued }} <i class="iconfont icon-xueya icon"></i>
                </div>
                <!-- <div class="infos-item" @click="(showTw = true)">
                  体温:{{ item.tw }} <i class="iconfont icon-tiwen icon"></i>
                </div> -->
                <div class="infos-item" @click="(showXl = true)">
                  心率:{{ item.xinlv }} <i class="iconfont icon-xinlv icon"></i>
                </div>
                <div class="infos-item" @click="(showXt = true)">
                  血糖:{{ item.xuet }} <i class="iconfont icon-xuetang icon"></i>
                </div>
                <div class="infos-item" @click="showPop = true">
                  运动:{{ item.ydzt | ydType }}
                </div>
                <div class="infos-item" @click="showPop = true">
                  睡眠:{{ item.smzt | smType }}
                </div>
                <div class="infos-item" @click="showPop = true">
                  呼吸:{{ item.hxzt | hxType }}
                </div>
                <!-- <div class="infos-item" @click="(showXya = true)">
                  血氧: <i class="iconfont icon-xueyang icon"></i>
                </div> -->
              </div>
            </div>
            <div class="detail-right">
              <div class="item">
                <el-tooltip class="item" effect="dark" content="视频" placement="top" :enterable="false">
                  <i class="iconfont icon-shipin"></i>
                </el-tooltip>
              </div>
              <div class="item">
                <el-tooltip class="item" effect="dark" content="录像" placement="top" :enterable="false">
                  <i class="iconfont icon-luxiang"></i>
                </el-tooltip>
              </div>
              <div class="item">
                <el-tooltip class="item" effect="dark" content="燃气" placement="top" :enterable="false">
                  <i class="iconfont icon-ranqi"></i>
                </el-tooltip>
              </div>
              <div class="item">
                <el-tooltip class="item" effect="dark" content="烟雾" placement="top" :enterable="false">
                  <i class="iconfont icon-yanwu"></i>
                </el-tooltip>
              </div>
              <div class="item">
                <el-tooltip class="item" effect="dark" content="漏水" placement="top" :enterable="false">
                  <i class="iconfont icon-loushui"></i>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div> -->
    </div>
    <div class="zhylContent-right">
      <div class="zhylContent-right-box">
        <div class="title">
          <div class="title-left">待办目录</div>
          <el-popover placement="bottom" trigger="click">

            <div class="title-right" slot="reference">更多</div>
          </el-popover>
        </div>
        <div class="zhylContent-right-box-temp">
          <div class="com-scroll com-scroll1" ref="scroll" :style="{ height: dbs.length * 53 + 'px' }">
            <div class="row" v-for="(item, index) in dbs" :key="index" @click="scrollClick1(index)">
              <div class="time">{{ item.fwsj }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="number">{{ item.number }}床</div>
              <div class="type">{{ item.type }}</div>
              <div class="status">
                <span style="color:#FF0000" v-if="item.status == 1">待响应</span>
                <span style="color:#44D7B6" v-else-if="item.status == 2">已处理</span>
              </div>
              <div class="tip" v-if="index == scrollIndex1">
                <el-button type="primary" size="mini" @click="openToDoList(item.id)">查看</el-button>
                <el-button type="primary" size="mini" @click="openToDoList(item.id)">处置</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zhylContent-right-box">
        <div class="title">
          <div class="title-left">安防异常</div>
          <el-popover placement="bottom" trigger="click">
            <el-button type="primary" size="mini">查看</el-button>
            <el-button type="primary" size="mini">处置</el-button>
            <div class="title-right" slot="reference">更多</div>
          </el-popover>
        </div>
        <div class="zhylContent-right-box-temp">
          <div class="com-scroll com-scroll2" ref="scroll2" :style="{ height: afycs.length * 53 + 'px' }">
            <div class="row" v-for="(item, index) in afycs" :key="index" @click="scrollClick2(index)">
              <div class="time">{{ item.time }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="number">{{ item.number }}床</div>
              <div class="type">{{ item.type }}</div>
              <div class="status">
                <span style="color:#FF0000" v-if="item.status == 1">待响应</span>
                <span style="color:#44D7B6" v-else-if="item.status == 2">已处理</span>
              </div>
              <div class="tip" v-if="index == scrollIndex2">
                <el-button type="primary" size="mini" @click="openSecurityAnomaly(item.id)">查看</el-button>
                <el-button type="primary" size="mini" @click="openSecurityAnomaly(item.id)">处置</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zhylContent-right-box">
        <div class="title">
          <div class="title-left">健康异常</div>
          <el-popover placement="bottom" trigger="click">
            <el-button type="primary" size="mini">查看</el-button>
            <el-button type="primary" size="mini">处置</el-button>
            <div class="title-right" slot="reference">更多</div>
          </el-popover>
        </div>
        <div class="zhylContent-right-box-temp">
          <div class="com-scroll com-scroll3" ref="scroll3" :style="{ height: jkycs.length * 53 + 'px' }">
            <div class="row" v-for="(item, index) in jkycs" :key="index" @click="scrollClick3(index)">
              <div class="time">{{ item.time }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="number">{{ item.number }}床</div>
              <div class="type">{{ item.type }}</div>
              <div class="status">
                <span style="color:#FF0000" v-if="item.status == 1">待响应</span>
                <span style="color:#44D7B6" v-else-if="item.status == 2">已处理</span>
              </div>
              <div class="tip" v-if="index == scrollIndex3">
                <el-button type="primary" size="mini" @click="openAbnormalHealth(item.id)">查看</el-button>
                <el-button type="primary" size="mini" @click="openAbnormalHealth(item.id)">处置</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zhylContent-right-box">
        <div class="title">
          <div class="title-left">紧急救助</div>
          <el-popover placement="bottom" trigger="click">
            <el-button type="primary" size="mini">查看</el-button>
            <el-button type="primary" size="mini">处置</el-button>
            <div class="title-right" slot="reference">更多</div>
          </el-popover>
        </div>
        <div class="zhylContent-right-box-temp">
          <div class="com-scroll com-scroll4" ref="scroll4" :style="{ height: jjjzs.length * 53 + 'px' }">
            <div class="row" v-for="(item, index) in jjjzs" :key="index" @click="scrollClick4(index)">
              <div class="time">{{ item.time }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="number">{{ item.number }}床</div>
              <div class="type">{{ item.type }}</div>
              <div class="status">
                <span style="color:#FF0000" v-if="item.status == 1">待响应</span>
                <span style="color:#44D7B6" v-else-if="item.status == 2">已处理</span>
              </div>
              <div class="tip" v-if="index == scrollIndex4">
                <el-button type="primary" size="mini" @click="openEmergencyAssistance(item.id)">查看</el-button>
                <el-button type="primary" size="mini" @click="openEmergencyAssistance(item.id)">处置</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Organ v-if="showOrgan" @close="closeOrgan"></Organ>
    <Rank v-if="showRank" @close="closeRank"></Rank>
    <!-- 上门服务 -->
    <div v-if="showSmDia" class="rzDia">
      <div class="rzDia-title">
        上门服务
      </div>
      <div class="rzDia-content">
        <div class="rzDia-content-close"><i class="el-icon-close" @click="(showSmDia = !showSmDia)"></i></div>
        <table class="diaTable" border="0">
          <thead>
            <td>序号</td>
            <td>姓名</td>
            <td>年龄</td>
            <td>身份证</td>
            <td>家庭住址</td>
            <td>所属机构名称</td>
            <td>服务人员</td>
            <td>服务项目</td>
            <td>服务内容</td>
            <td>服务机构名称</td>
            <td>服务评价</td>
            <td>服务轨迹</td>
            <td>服务时间</td>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>李明明</td>
              <td>88</td>
              <td>130730199801011234</td>
              <td>石家庄市桥西区红旗大街256号</td>
              <td>东安庄乡卫生院</td>
              <td>李晓明</td>
              <td>高血压</td>
              <td>高血压</td>
              <td>东安庄乡卫生院</td>
              <td>优</td>
              <td>
                <i @click="showSmDitus" style="cursor:pointer;" class="iconfont icon-ditu"></i>
              </td>
              <td>2022-12-01</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="showSmDitu" class="dituDia">
      <div class="dituDia-content">
        <div class="dituDia-content-close"><i class="el-icon-close" @click="(showSmDitu = !showSmDitu)"></i></div>
        <img src="../img/smDitu.png" alt="">
      </div>
    </div>
    <!-- 电子围栏 -->
    <div v-if="showWlDia" class="rzDia">
      <div class="rzDia-title">
        电子围栏
      </div>
      <div class="rzDia-content">
        <div class="rzDia-content-close"><i class="el-icon-close" @click="(showWlDia = !showWlDia)"></i></div>
        <div class="box">
          <div class="box-left">
            <table border="0" class="diaTable">
              <thead>
                <td>姓名</td>
                <td>年龄</td>
                <td>性别</td>
                <td>所属机构</td>
              </thead>
              <tbody>
                <tr>
                  <td>李明明</td>
                  <td>88</td>
                  <td>男</td>
                  <td>古城营镇卫生院</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="box-right">
            <div class="wlTabs">
              <div :class="isCircle ? 'active' : 'unActive'" @click="(isCircle = true)" class="item">圆形围栏</div>
              <div :class="!isCircle ? 'active' : 'unActive'" @click="(isCircle = false)" class="item">多边形围栏</div>
            </div>
            <div class="box-right-top">
              <el-form size="mini" label-width="1rem" :model="wlForm">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="围栏名称">
                      <el-input v-model="wlForm.name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="报警类型">
                      <el-input v-model="wlForm.name2"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="报警时段">
                      <el-input v-model="wlForm.name3"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="围栏记录">
                      <el-input v-model="wlForm.name4"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-button size="mini" type="primary" @click="wlCommit">保存</el-button>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div class="box-right-center" v-if="isCircle">
              <div style="text-align:center;color:#fff;">半径设置</div>
              <div style="width:80%;margin-left:10%;">
                <el-slider :min="0" :max="3000" v-model="wlFw" show-input></el-slider>
              </div>
            </div>
            <div class="box-right-bot">
              <img v-if="isCircle" src="../img/wlDitu1.png" alt="">
              <img v-else-if="!isCircle" src="../img/wlDitu2.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showXt" class="rzDia">
      <div class="rzDia-title">
        血糖概览
      </div>
      <div class="rzDia-content">
        <div class="rzDia-content-close"><i class="el-icon-close" @click="(showXt = !showXt)"></i></div>
        <div class="box">
          <xtRankChart></xtRankChart>
        </div>
      </div>
    </div>
    <div v-if="showTw" class="rzDia">
      <div class="rzDia-title">
        体温概览
      </div>
      <div class="rzDia-content">
        <div class="rzDia-content-close"><i class="el-icon-close" @click="(showTw = !showTw)"></i></div>
        <div class="box">
          <twRankChart></twRankChart>
        </div>
      </div>
    </div>
    <div v-if="showXya" class="rzDia">
      <div class="rzDia-title">
        血氧概览
      </div>
      <div class="rzDia-content">
        <div class="rzDia-content-close"><i class="el-icon-close" @click="(showXya = !showXya)"></i></div>
        <div class="box">
          <xyaRankChart></xyaRankChart>
        </div>
      </div>
    </div>
    <div v-if="showXl" class="rzDia">
      <div class="rzDia-title">
        心率概览
      </div>
      <div class="rzDia-content">
        <div class="rzDia-content-close"><i class="el-icon-close" @click="(showXl = !showXl)"></i></div>
        <div class="box">
          <xlRankChart></xlRankChart>
        </div>
      </div>
    </div>
    <div v-if="showXy" class="rzDia">
      <div class="rzDia-title">
        血压概览
      </div>
      <div class="rzDia-content">
        <div class="rzDia-content-close"><i class="el-icon-close" @click="(showXy = !showXy)"></i></div>
        <div class="box">
          <xyRankChart></xyRankChart>
        </div>
      </div>
    </div>
    <div v-if="showYyfw" class="rzDia">
      <div class="rzDia-title" style="width:25%">
        医养结合服务项目工作表
      </div>
      <div class="rzDia-content">
        <div class="rzDia-content-close"><i class="el-icon-close" @click="(showYyfw = !showYyfw)"></i></div>
        <div class="box1">
          <yyfw></yyfw>
        </div>
      </div>
    </div>
    <!-- 智慧医疗 -->
    <SmartConsultation v-if="showSmartConsultation"></SmartConsultation>
    <!-- 智慧公卫 -->
    <SmartPublicGuard v-if="showSmartPublicGuard"></SmartPublicGuard>
    <!-- 智慧养老 -->
    <SmartElderlyCare v-if="showSmartElderlyCare"></SmartElderlyCare>
    <!-- 智慧家签 -->
    <WisdomHomeSignature v-if="false"></WisdomHomeSignature>
    <!-- 待办目录 -->
    <ToDoList v-if="showToDoList" @close="closeToDoList"></ToDoList>
    <!-- 安防异常 -->
    <SecurityAnomaly v-if="showSecurityAnomaly" @close="closeSecurityAnomaly"></SecurityAnomaly>
    <!-- 健康异常 -->
    <AbnormalHealth v-if="showAbnormalHealth" @close="closeAbnormalHealth"></AbnormalHealth>
    <!-- 紧急救助 -->
    <EmergencyAssistance v-if="showEmergencyAssistance" :selectId="selectIdVal" @close="closeEmergencyAssistance"></EmergencyAssistance>
    <!-- 绑定设备 -->
    <BindPopup v-if="showBindPopup" @close="closeBindPopup"></BindPopup>
    <!-- 报警信息 -->
    <CallThePolice v-if="showCallThePolice" @close="closeCallThePolice"></CallThePolice>
    <!-- 详情信息 -->
    <SeeInfo v-if="showSeeInfo" :selectId="selectId" @close="closeSeeInfo"></SeeInfo>
    <!-- 护理计划 -->
    <CarePlan v-if="showCarePlan" @close="closeCarePlan"></CarePlan>
    <!-- 用药计划 -->
    <MedicationPlan v-if="showMedicationPlan" @close="closeMedicationPlan"></MedicationPlan>
    <Pop v-if="showPop"></Pop>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Organ from './organ.vue'
import Rank from './rank.vue'
import yyfw from './yyfw.vue'
import xtRankChart from '@/components/yiliao2/xttjDialog/gather.vue'
import xyRankChart from '@/components/yiliao2/xytjDialog/gather.vue'
import xlRankChart from '@/components/yiliao2/xltjDialog/gather.vue'
import twRankChart from '@/components/yiliao2/twtjDialog/gather.vue'
import xyaRankChart from '@/components/yiliao2/xyangtjDialog/gather.vue'
import SmartConsultation from './smartHealthcare.vue'
import SmartPublicGuard from './smartPublicGuard.vue'
import SmartElderlyCare from './smartElderlyCare.vue'
import WisdomHomeSignature from './wisdomHomeSignature.vue'
import ToDoList from './toDoList.vue'
import SecurityAnomaly from './securityAnomaly.vue'
import AbnormalHealth from './abnormalHealth.vue'
import EmergencyAssistance from './emergencyAssistance.vue'
import BindPopup from './bindPopup.vue'
import CallThePolice from './callThePolice.vue'
import SeeInfo from './seeInfo.vue'
import CarePlan from './carePlan.vue'
import MedicationPlan from './medicationPlan/medicationPlan.vue'
import Pop from './pop/pop.vue'
export default {
  components: {
    Organ,
    Rank,
    yyfw,
    xtRankChart,
    xyRankChart,
    xlRankChart,
    twRankChart,
    xyaRankChart,
    SmartConsultation,
    SmartPublicGuard,
    SmartElderlyCare,
    WisdomHomeSignature,
    ToDoList,
    SecurityAnomaly,
    AbnormalHealth,
    EmergencyAssistance,
    BindPopup,
    CallThePolice,
    SeeInfo,
    CarePlan,
    MedicationPlan,
    Pop
  },
  data () {
    // 这里存放数据
    return {
      options1: [],
      value2: 1,
      wlFw: 0,
      wlForm: {
        name: '',
        name2: '',
        name3: '',
        name4: ''
      },
      rzForm: {
        xh: '',
        name: '',
        sfzh: '',
        xb: '',
        age: '',
        address: '',
        rqfl: '',
        ylbh: '',
        ssjgxz: '',
        ssjgjd: '',
        fwdj: '',
        hldj: '',
        rzsj: '',
        rzts: '',
        sbbh: '',
        jslxdh: '',
        jsname: '',
        yqgx: '',
        brdh: '',
        fwry: '',
        zsfw: '',
        sfdj: '',
        createtime: '',
        jddw: '',
        jgbh: ''
      },
      headerMenuIndex: 0,
      menuIndex: 'glkb',
      showXt: false,
      showXy: false,
      showXya: false,
      showTw: false,
      showXl: false,
      showJgpm: false,
      showXjjg: false,
      showYyfw: false,
      showKfjh: false,
      showRzDia: false, // 入住登记
      showSmDia: false, // 上门服务
      showSmDitu: false, // 上门服务地图
      showWlDia: false, // 电子围栏
      isCircle: false, // 是否是圆形围栏
      pageParams: {
        start: 1,
        length: 16,
        sxtj: '',
        total: 0
      },
      loginInfo: {},
      searchVal: '',
      isCollapse: false,
      timerId: null,
      dataList: [],
      afycLsit: [
        // {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '张三',
        //   number: '103',
        //   type: '随访',
        //   status: '1'
        // }, {
        //   time: '2022-11-22 10:10',
        //   name: '李四',
        //   number: '206',
        //   type: '随访',
        //   status: '2'
        // }
      ],
      showOrgan: false,
      showRank: false,
      scrollIndex1: -1,
      scrollIndex2: -1,
      scrollIndex3: -1,
      scrollIndex4: -1,
      showSmartConsultation: false,
      showSmartPublicGuard: false,
      showSmartElderlyCare: false,
      showToDoList: false,
      showSecurityAnomaly: false,
      showAbnormalHealth: false,
      showEmergencyAssistance: false,
      showBindPopup: false,
      showCallThePolice: false,
      showSeeInfo: false,
      selectId: '',
      selectIdVal: '',
      showPagination: true,
      dbs: [],
      afycs: [],
      jkycs: [],
      jjjzs: [],
      showCarePlan: false,
      showMedicationPlan: false,
      showPop: false,
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      currentPage: 0,
      total: 400
    }
  },
  computed: {
    ...mapState(['SocketData'])
  },
  watch: {
    SocketData (val) {
      if (val.length > 0 && val[0].msgtype === 'zhdpsyts') {
        this.showCallThePolice = true
      }
      // if (val.length > 0) {
      //   this.showCallThePolice = true
      // }
    }
  },
  filters: {
    ydType (val) {
      if (val === null || val === '') return ''
      const arr = ['无', '静止', '活跃']
      return arr[val]
    },
    smType (val) {
      if (val === null || val === '') return ''
      const arr = ['深睡', '浅睡', '清醒', '无']
      return arr[val]
    },
    hxType (val) {
      if (val === null || val === '') return ''
      const arr = ['正常', '呼吸过快', '呼吸过低', '无']
      return arr[val]
    },
    rzsjType (val) {
      if (!val) return '-'
      const arr = val.split(',')
      return arr[0]
    }
  },
  async mounted () {
    this.loginInfo = this.$store.state.loginInfo
    await this.getData()
    // this.animationInterval()
    // this.dataInterval()
    // this.watchScroll()
    this.getDb()
    this.getAfyc()
    this.getJkyc()
    this.getJjjz()
  },
  created () {
    this.jgChange()
  },
  methods: {
    async jgChange (e) {
      try {
        this.pageParams.length = this.query1.pageSize
        console.log(this.$store.state.loginInfo.jgbh, 'loginInfo:+++++')
        const obj = {
          start: 0,
          length: this.query1.pageSize + '',
          ssjgbh: this.$store.state.loginInfo.jgbh,
          jgbh: this.$store.state.loginInfo.jgbh,
          sxzt: '3',
          jgjb: ''
        }
        const { data: res } = await this.$http.get('/api/jkct/findjglist', { params: obj })
        console.log('机构列表+++', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.options1 = res.data.map(item => {
          return {
            label: item.jddw,
            value: item.jgbh,
            id: item.id
          }
        })
      } catch (error) {
        console.log('机构 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async jgChangeMethod (e) {
      console.log(e, '++++++++')
      const start = this.pageParams.start === 1 ? '0' : (this.pageParams.start - 1) * 16 + ''
      const params = {
        jgbh: e || '',
        ssjgbh: e || '',
        start: start,
        length: this.pageParams.length + '',
        sxtj: this.pageParams.sxtj
      }
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-122245615
      const { data: res } = await this.$http.get('/ykdzhylfwlbs', { params: params })
      // const { data: res } = await this.$http.get('/api/jkct/findjglist', { params: params })
      console.log('信息res', res)
      if (res.code !== 0) return this.$message.error(res.msg || res.message)
      this.dataList = res.data
      this.pageParams.total = res.recordsTotal
    },
    open () {
      this.showPagination = !this.showPagination
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageParams.start = val
      this.getData()
      // console.log(`当前页: ${val}`)
    },
    scrollClick1 (index) {
      this.scrollIndex1 = index
    },
    scrollClick2 (index) {
      this.scrollIndex2 = index
    },
    scrollClick3 (index) {
      this.scrollIndex3 = index
    },
    scrollClick4 (index) {
      this.scrollIndex4 = index
    },
    openToDoList (id) {
      this.selectIdVal = id
      this.showToDoList = true
    },
    closeToDoList () {
      this.selectIdVal = ''
      this.showToDoList = false
    },
    openSecurityAnomaly (id) {
      this.selectIdVal = id
      this.showSecurityAnomaly = true
    },
    closeSecurityAnomaly () {
      this.selectIdVal = ''
      this.showSecurityAnomaly = false
    },
    openAbnormalHealth (id) {
      this.selectIdVal = id
      this.showAbnormalHealth = true
    },
    closeAbnormalHealth () {
      this.selectIdVal = ''
      this.showAbnormalHealth = false
    },
    openEmergencyAssistance (id) {
      this.selectIdVal = id
      this.showEmergencyAssistance = true
    },
    closeEmergencyAssistance () {
      this.selectIdVal = ''
      this.showEmergencyAssistance = false
    },
    openOrgan () {
      this.showOrgan = true
    },
    closeOrgan () {
      this.showOrgan = false
    },
    openRank () {
      this.showRank = true
    },
    closeRank () {
      this.showRank = false
    },
    openBindPopup () {
      this.showBindPopup = true
    },
    closeBindPopup () {
      this.showBindPopup = false
    },
    openCallThePolice () {
      this.showCallThePolice = true
    },
    closeCallThePolice () {
      this.showCallThePolice = false
    },
    openSeeInfo (id) {
      this.selectId = id + ''
      this.showSeeInfo = true
    },
    closeSeeInfo () {
      this.showSeeInfo = false
      this.selectId = ''
    },
    handleOpen (key, keyPath) {
      // if (key === '/ycyl') return
      // window.sessionStorage.setItem('activePath', key)
      // this.activePath = key
      // this.$router.push(key)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },

    leftMenuClick (item) {
      if (item.value === '') return
      this.menuIndex = item.value
      if (item.value === 'zz') {
        this.$router.push('/referral')
      } else if (item.value === 'hz') {
        this.$router.push('/consultation')
      } else if (item.value === 'ycyl') {
        // this.$router.push('/bultrasonic')
      } else if (item.value === 'ycbc') {
        this.$router.push('/bultrasonic')
      }
    },
    showSmDitus () {
      this.showSmDitu = true
    },
    wlCommit () {
      this.$message.success('提交成功！')
    },
    rzCommit () {
      this.$message.success('提交成功！')
      this.showRzDia = false
    },
    async getData () {
      const start = this.pageParams.start === 1 ? '0' : (this.pageParams.start - 1) * 10 + ''
      const params = {
        jgbh: this.loginInfo.jgbh || '',
        ssjgbh: this.loginInfo.jgbh || '',
        start: start,
        // jgjb: 4,
        length: this.pageParams.length + '',
        sxtj: this.pageParams.sxtj
      }
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-122245615
      const { data: res } = await this.$http.get('/ykdzhylfwlbs', { params: params })
      console.log('信息res', res)
      if (res.code !== 0) return this.$message.error(res.msg || res.message)
      this.dataList = res.data
      this.pageParams.total = res.recordsTotal
    },
    async getDb () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-81591712
      try {
        const { data: res } = await this.$http.get('/ykdzhylgjxxs', {
          params: {
            jgbh: this.loginInfo.jgbh || '',
            ssjgbh: this.loginInfo.jgbh || '',
            start: '0',
            length: '10',
            lytype: '01'
          }
        })
        console.log('待办', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('待办请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getAfyc () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-81591712
      try {
        const { data: res } = await this.$http.get('/ykdzhylgjxxs', {
          params: {
            jgbh: this.loginInfo.jgbh || '',
            ssjgbh: this.loginInfo.jgbh || '',
            start: '0',
            length: '10',
            lytype: '02'
          }
        })
        console.log('安防异常', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('安防异常请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getJkyc () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-81591712
      try {
        const { data: res } = await this.$http.get('/ykdzhylgjxxs', {
          params: {
            jgbh: this.loginInfo.jgbh || '',
            ssjgbh: this.loginInfo.jgbh || '',
            start: '0',
            length: '10',
            lytype: '03'
          }
        })
        console.log('健康异常', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('健康异常请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getJjjz () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-81591712
      try {
        const { data: res } = await this.$http.get('/ykdzhylgjxxs', {
          params: {
            jgbh: this.loginInfo.jgbh || '',
            ssjgbh: this.loginInfo.jgbh || '',
            start: '0',
            length: '10',
            lytype: '04'
          }
        })
        console.log('紧急救助', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('紧急救助请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openCarePlan () {
      this.showCarePlan = true
    },
    closeCarePlan () {
      this.showCarePlan = false
    },
    openMedicationPlan () {
      this.showMedicationPlan = true
    },
    closeMedicationPlan () {
      this.showMedicationPlan = false
    },
    dataInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        if (this.pageParams.start < Math.ceil(34 / this.pageParams.length)) {
          this.pageParams.start++
          // // console.log(this.pageParams.start)
        } else {
          this.pageParams.start = 1
        }
        this.getData()
      }, 8000)
    },
    pre () {
      if (this.pageParams.start === 1) {
        this.$message.warning('已经是第一页了')
      } else {
        this.pageParams.start--
        this.getData()
      }
    },
    next () {
      this.pageParams.start++
      this.getData()
    },
    refresh () {
      this.pageParams.start = 1
      this.getData()
    },
    search () {
      this.pageParams.start = 1
      this.getData()
    },
    tabClick (item, index) {
      this.headerMenuIndex = index
      if (item.value === 'index') {
        this.menuIndex = 'glkb'
      } else if (item.value === 'ruzhu') {
        this.showRzDia = true
      } else if (item.value === 'truck') {
        this.showSmDia = true
      } else if (item.value === 'fence') {
        this.showWlDia = true
      } else {
        this.$message.warning('功能暂未开放！')
      }
    },
    animationInterval () {
      const styles1 = document.styleSheets
      const runkeyframes1 = `@keyframes rowup1 {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -${(this.afycLsit.length - 7) * 53}px, 0);
            display: none;
        }
      }`
      styles1[0].insertRule(runkeyframes1)
      // Array.from(styles).map((item, index) => {
      //   item.insertRule(runkeyframes)
      // })
      this.$refs.scroll.style.animationName = 'rowup1'
      this.$refs.scroll.style.animationDuration = this.afycLsit.length + 's'
      this.$refs.scroll.style.animationIterationCount = 'infinite'
      this.$refs.scroll.style.animationTimingFunction = 'linear'
      this.$refs.scroll.style.animationDelay = '0'

      const styles2 = document.styleSheets
      const runkeyframes2 = `@keyframes rowup2 {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -${(this.afycLsit.length - 7) * 53}px, 0);
            display: none;
        }
      }`
      styles2[0].insertRule(runkeyframes2)
      // Array.from(styles).map((item, index) => {
      //   item.insertRule(runkeyframes)
      // })
      this.$refs.scroll2.style.animationName = 'rowup2'
      this.$refs.scroll2.style.animationDuration = this.afycLsit.length + 's'
      this.$refs.scroll2.style.animationIterationCount = 'infinite'
      this.$refs.scroll2.style.animationTimingFunction = 'linear'
      this.$refs.scroll2.style.animationDelay = '0'

      const styles3 = document.styleSheets
      const runkeyframes3 = `@keyframes rowup3 {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -${(this.afycLsit.length - 7) * 53}px, 0);
            display: none;
        }
      }`
      styles3[0].insertRule(runkeyframes3)
      // Array.from(styles).map((item, index) => {
      //   item.insertRule(runkeyframes)
      // })
      this.$refs.scroll3.style.animationName = 'rowup3'
      this.$refs.scroll3.style.animationDuration = this.afycLsit.length + 's'
      this.$refs.scroll3.style.animationIterationCount = 'infinite'
      this.$refs.scroll3.style.animationTimingFunction = 'linear'
      this.$refs.scroll3.style.animationDelay = '0'

      const styles4 = document.styleSheets
      const runkeyframes4 = `@keyframes rowup4 {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -${(this.afycLsit.length - 7) * 53}px, 0);
            display: none;
        }
      }`
      styles4[0].insertRule(runkeyframes4)
      // Array.from(styles).map((item, index) => {
      //   item.insertRule(runkeyframes)
      // })
      this.$refs.scroll4.style.animationName = 'rowup4'
      this.$refs.scroll4.style.animationDuration = this.afycLsit.length + 's'
      this.$refs.scroll4.style.animationIterationCount = 'infinite'
      this.$refs.scroll4.style.animationTimingFunction = 'linear'
      this.$refs.scroll4.style.animationDelay = '0'
      // if (this.timer) {
      //   clearInterval(this.timer)
      // }
      // this.timer = setInterval(() => {
      //   this.initData()
      // }, 3 * 1000 * 60)
    },

    stop (index) {
      // console.log('进入')
      // this.$refs.scroll.style.animationPlayState = 'paused'
      // this.$refs.scroll2.style.animationPlayState = 'paused'
      // this.$refs.scroll3.style.animationPlayState = 'paused'
      // this.$refs.scroll4.style.animationPlayState = 'paused'
      this.$refs[index].style.animationPlayState = 'paused'
    },
    start (index) {
      // console.log('离开')
      // this.$refs.scroll.style.animationPlayState = ''
      // this.$refs.scroll2.style.animationPlayState = ''
      // this.$refs.scroll3.style.animationPlayState = ''
      // this.$refs.scroll4.style.animationPlayState = ''
      // this.scrollIndex1 = this.scrollIndex2 = this.scrollIndex3 = this.scrollIndex4 = -1
      this.$refs[index].style.animationPlayState = ''
      this.scrollIndex1 = this.scrollIndex2 = this.scrollIndex3 = this.scrollIndex4 = -1
    },
    watchScroll () {
      const scroll1 = document.querySelector('.com-scroll1')
      scroll1.addEventListener('mouseenter', () => this.stop('scroll'))
      scroll1.addEventListener('mouseleave', () => this.start('scroll'))
      const scroll2 = document.querySelector('.com-scroll2')
      scroll2.addEventListener('mouseenter', () => this.stop('scroll2'))
      scroll2.addEventListener('mouseleave', () => this.start('scroll2'))
      const scroll3 = document.querySelector('.com-scroll3')
      scroll3.addEventListener('mouseenter', () => this.stop('scroll3'))
      scroll3.addEventListener('mouseleave', () => this.start('scroll3'))
      const scroll4 = document.querySelector('.com-scroll4')
      scroll4.addEventListener('mouseenter', () => this.stop('scroll4'))
      scroll4.addEventListener('mouseleave', () => this.start('scroll4'))
    }
  },
  beforeDestroy () {
    clearInterval(this.timerId)
  }
}
</script>

<style lang="less" scoped>
.zhylContent {
  // position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  &-center {
    width: calc(100% - 400px);
    height: 100%;
    position: relative;

    &-select {
      display: flex;
      align-items: center;
      height: 40px;

      .open {
        color: #000;
        font-size: 28px;
        cursor: pointer;
      }

      .item {
        display: flex;
        align-items: center;
        margin: 0 10px;

        /deep/ .el-input__inner {
          height: 30px;
          background-color: transparent;
          border: 1px solid #000;
          color: #000;
          font-size: 16px;
        }

        /deep/.el-input__suffix {
          transform: rotate(-90deg);

        }

        /deep/.el-select .el-input .el-select__caret {
          color: #000;
        }

        .el-button {
          display: flex;
          align-items: center;
          height: 30px;
        }

        /deep/.el-button--primary {
          background-color: transparent;
          border-color: #000;
        }

        /deep/.el-button--primary {
          color: #000;
          font-size: 16px;
        }

        /deep/.el-icon-arrow-down {
          transform: rotate(-90deg);
        }
      }
    }

    &-search {
      display: flex;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 246px;
      height: 30px;
      // background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      background-color: rgb(37, 160, 255);
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      // border: 1px solid #1F72D3;
      box-sizing: border-box;
      overflow: hidden;

      input {
        outline-style: none;
        border: none;
        height: 30px;
        line-height: 30px;
        width: 170px;
        margin-left: 30px;
        color: #fff;
        background-color: rgb(37, 160, 255);
        // background-color: #0E67AD;
        //background-color: rgba(18, 68, 108, 0);
      }

      ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #fff;
        font-size: 14px;
        letter-spacing: 1px;
      }
    }

    &-content {
      // height: calc(100% - 80px);
      height: calc(100% - 70px);
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      &-box {
        width: 25%;
        height: 25%;
        border: 3px solid #fff;
        box-sizing: border-box;

        .title {
          width: 80%;
          height: 15%;
          // background-image: url('../img/contentCenterBoxTitleBg.png');
          // background-position: 50% 50%;
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
          background-color: rgb(37, 160, 255);
          color: #fff;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding-left: 34px;
          border-radius: 5px;
          box-sizing: border-box;

          span {
            font-size: 16px;
          }
        }

        .detail {
          width: 100%;
          height: 85%;
          // background-image: url('../img/contentCenterBoxDetailBg.png');
          // background-position: 50% 50%;
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
          background-color: rgb(37, 160, 255);
          margin-top: 2px;
          position: relative;
          color: #fff;
          padding: 0 5px;
          box-sizing: border-box;
          display: flex;

          .corner {
            position: absolute;
            top: 0;
            right: 0;
            width: 58%;
            height: 10%;
            background-color: orange;
            display: flex;
            border-radius: 2px;

            .item {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50%;
              height: 100%;
              text-align: center;
              color: #fff;
              font-size: 13px;
              border-radius: 2px;
            }

            .item1 {
              background-color: #44D7B6;
            }
          }

          &-left {
            width: 90%;
            box-sizing: border-box;

            .content-info {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width: 100%;
              height: calc(100% - 56px);

              .pInfo {
                display: flex;
                align-items: center;

                .name {
                  font-size: 18px;
                }

                .sex {
                  color: #fff;
                  margin: 0 10px;
                }

                .age {
                  font-size: 14px;
                }
              }

              .rzInfo {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: flex;
                align-items: center;
                width: 100%;
                font-size: 14px;

                span {
                  white-space: nowrap;
                }

                .icon {
                  margin: 0 10px;
                  color: #fff;
                }

                .font {
                  margin: 0 10px;
                  cursor: pointer;
                }

                .bind {
                  width: 14px;
                  height: 14px;
                  cursor: pointer;
                }

                .flex {
                  display: flex;
                  align-items: center;
                }
              }

              .rzInfo1 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 99%;
                font-size: 14px;
              }

              .dnInfo {
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-size: 14px;

                .margin-left {
                  margin-left: 10px;
                }

                .info-width {
                  width: 50%;
                }
              }

              .glInfo {
                display: flex;
                align-items: center;
                width: calc(100% - 5px);
                border-bottom: 1px solid #fff;
                box-sizing: border-box;
                font-size: 14px;

                &-left {
                  display: flex;
                  margin-right: 10px;
                  // justify-content: space-around;
                  // width: 40%;

                  i {
                    margin-right: 4px;
                  }
                }

                &-right {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  width: 60%;
                  // justify-content: space-around;
                  .span {
                    margin-right: 10px;
                  }

                  span {
                    white-space: nowrap;
                  }
                }
              }
            }

            .infos {
              display: flex;
              // justify-content: space-between;
              // align-content: space-evenly;
              flex-wrap: wrap;
              height: 56px;
              font-size: 14px;

              &-item {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 33.3%;
                cursor: pointer;

                .icon {
                  color: #fff;
                }
              }
            }
          }

          &-right {
            width: 10%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            font-size: 10px;
            padding: 0 2px;
            border-right: 1px solid #fff;
            border-left: 1px solid #fff;
            box-sizing: border-box;
            height: 90%;

            .item {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 10px;
              color: #fff;
              white-space: nowrap;
              cursor: pointer;

              .iconfont {
                font-size: 14px;
                color: #fff;
                // transform: scale(.8);
              }
            }
          }
        }
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }

  &-right {
    width: 390px;

    &-box {
      height: 24%;
      width: 100%;
      margin-bottom: 2%;
      background-color: rgba(18, 68, 108, 0.1);
      overflow: hidden;
      color: white;

      .title {
        height: 40px;
        // background-image: url('../img/contentRightTitleBg.png');
        // background-position: 50% 50%;
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        background-color: rgb(37, 160, 255);
        display: flex;
        justify-content: space-between;
        line-height: 45px;
        font-size: 16px;

        &-left {
          padding-left: 30px;
        }

        &-right {
          cursor: pointer;
          padding-right: 20px;
        }
      }

      .com-scroll1 {
        animation: 100s rowup1 linear infinite normal;
      }

      .com-scroll2 {
        animation: 100s rowup2 linear infinite normal;
      }

      .com-scroll3 {
        animation: 100s rowup3 linear infinite normal;
      }

      .com-scroll4 {
        animation: 100s rowup4 linear infinite normal;
      }

      &-temp {
        width: 100%;
        margin-top: 10px;
        overflow: hidden;

        .com-scroll {
          overflow: hidden;

          .row {
            position: relative;
            display: flex;
            text-align: center;
            justify-content: space-around;
            height: 30px;
            font-size: 12px;
            cursor: pointer;

            .tip {
              display: flex;
              align-items: center;
              position: absolute;
              right: -74px;
              z-index: 88;
              transform: translateX(-50%);
              bottom: -50px;
              min-height: 40px;
              padding: 10px;
              background-color: #fff;
            }

            .time {
              width: 174px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .type {
              white-space: nowrap;
            }

            .status {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .dituDia {
    position: absolute;
    z-index: 2006;
    top: 20px;
    left: calc(50% - 450px);
    width: 900px;
    height: 900px;
    color: #fff;
    background-image: url('../img/smBoxBg.png');
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &-content {
      position: relative;
      padding: 20px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      &-close {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 20px;
        color: #fff;
        cursor: pointer;
      }

      img {
        width: 860px;
      }
    }
  }

  .rzDia {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #062038;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    &-title {
      width: 150px;
      height: 50px;
      line-height: 60px;
      display: flex;
      justify-content: center;
      background-image: url('../img/dialogTitleBg.png');
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #fff;
      font-size: 20px;
    }

    &-content {
      position: relative;
      height: calc(100% - 60px);
      background-image: url('../img/dialogContentBg.png');
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 40px;
      box-sizing: border-box;
      margin-top: 10px;

      &-close {
        position: absolute;
        top: 4%;
        right: 2%;
        font-size: 24px;
        color: #fff;
        cursor: pointer;
      }

      .box {
        width: 100%;
        height: 100%;
      }

      // .diaTable {
      //   width: 100%;

      //   thead {
      //     height: 50px;
      //     background-color: #094E90;
      //     color: #fff;

      //     td {
      //       text-align: center;
      //     }
      //   }

      //   tbody {
      //     color: #01EEFD;

      //     tr {
      //       padding: 10px 0;

      //       td {
      //         text-align: center;
      //       }
      //     }
      //   }
      // }

      // .box {
      //   width: 100%;
      //   height: 100%;
      //   display: flex;
      //   justify-content: space-between;

      //   &-left {
      //     width: 25%;
      //     border: 2px solid #124368;
      //     box-sizing: border-box;
      //     background-color: #062038;

      //     .diaTable {
      //       width: 100%;

      //       thead {
      //         height: 50px;
      //         background-color: #094E90;
      //         color: #fff;

      //         td {
      //           text-align: center;
      //         }
      //       }

      //       tbody {
      //         color: #01EEFD;

      //         tr {
      //           padding: 10px 0;

      //           td {
      //             text-align: center;
      //           }
      //         }
      //       }
      //     }
      //   }

      //   &-right {
      //     width: 75%;
      //     position: relative;

      //     .wlTabs {
      //       position: absolute;
      //       top: 0;
      //       left: 0;
      //       display: flex;

      //       .item {
      //         padding: 5px 10px;
      //         font-size: 12px;
      //       }

      //       .unActive {
      //         background-color: #fff;
      //         color: #017CDB;
      //       }

      //       .active {
      //         background-color: #017CDB;
      //         color: #fff;
      //       }
      //     }

      //     &-top {
      //       box-sizing: border-box;
      //       border: 2px solid #124368;
      //       padding: 40px 0 10px 0;
      //     }

      //     &-bot {
      //       width: 90%;
      //       margin-left: 5%;

      //       img {
      //         width: 100%;
      //       }
      //     }
      //   }
      // }

      // .box1 {
      //   height: 600px;
      // }
    }
  }
}
</style>

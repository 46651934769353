<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-06-01 13:58:24
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-06-05 08:26:10
 * @FilePath: \visions\src\views\zhyl\runkanban\pop\tab1.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab">
    <!-- <div class="tab-line">
      <div class="tab-text">
        <span>探测模式</span>
      </div>
      <el-radio-group v-model="params.detectionMode">
        <el-radio label="0">实时探测模式</el-radio>
        <el-radio label="1">睡眠探测模式</el-radio>
      </el-radio-group>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>心率开关</span>
      </div>
      <el-switch
        v-model="params.heartRateSwitch"
        active-text="关闭"
        inactive-text="开启">
      </el-switch>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>呼吸开关</span>
      </div>
      <el-switch
        v-model="params.breathingSwitch"
        active-text="关闭"
        inactive-text="开启">
      </el-switch>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>睡眠开关</span>
      </div>
      <el-switch
        v-model="params.sleepSwitch"
        active-text="关闭"
        inactive-text="开启">
      </el-switch>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>长时间无人计时开关</span>
      </div>
      <el-switch
        v-model="params.longTimeNoTimerSwitch"
        active-text="关闭"
        inactive-text="开启">
      </el-switch>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>无人计时时长设置</span>
      </div>
      <div class="slider">
        <el-slider :min="30" :max="180" v-model="params.unmanneDuration"></el-slider>
      </div>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>存在开关</span>
      </div>
      <el-switch
        v-model="params.existSwitch"
        active-text="关闭"
        inactive-text="开启">
      </el-switch>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>异常挣扎开关</span>
      </div>
      <el-switch
        v-model="params.abnormalStruggleSwitch"
        active-text="关闭"
        inactive-text="开启">
      </el-switch>
    </div> -->
    <el-table :data="tableData" border height="99%">
      <el-table-column prop="id" label="id">
      </el-table-column>
      <el-table-column label="探测模式">
        <template slot-scope="scope">
          {{ scope.row.detectionMode | type1 }}
        </template>
      </el-table-column>
      <el-table-column label="心率开关">
        <template slot-scope="scope">
          {{ scope.row.heartRateSwitch | type2 }}
        </template>
      </el-table-column>
      <el-table-column label="呼吸开关">
        <template slot-scope="scope">
          {{ scope.row.breathingSwitch | type2 }}
        </template>
      </el-table-column>
      <el-table-column label="睡眠开关">
        <template slot-scope="scope">
          {{ scope.row.sleepSwitch | type2 }}
        </template>
      </el-table-column>
      <el-table-column label="长时间无人计时开关">
        <template slot-scope="scope">
          {{ scope.row.longTimeNoTimerSwitch | type2 }}
        </template>
      </el-table-column>
      <el-table-column prop="unmanneDuration" label="无人计时时长设置"></el-table-column>
      <el-table-column label="存在开关">
        <template slot-scope="scope">
          {{ scope.row.existSwitch | type2 }}
        </template>
      </el-table-column>
      <el-table-column label="异常挣扎开关">
        <template slot-scope="scope">
          {{ scope.row.abnormalStruggleSwitch | type2 }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: null,
      params: {
        detectionMode: null,
        heartRateSwitch: null,
        breathingSwitch: null,
        sleepSwitch: null,
        longTimeNoTimerSwitch: null,
        unmanneDuration: null,
        existSwitch: null,
        abnormalStruggleSwitch: null
      },
      tableData: [
        // {
        //   id: '1001',
        //   detectionMode: null,
        //   heartRateSwitch: null,
        //   breathingSwitch: null,
        //   sleepSwitch: null,
        //   longTimeNoTimerSwitch: null,
        //   unmanneDuration: null,
        //   existSwitch: null,
        //   abnormalStruggleSwitch: null
        // }
      ]
    }
  },
  created () {
    this.getInfo()
    this.timer = setInterval(() => {
      this.getInfo()
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
  filters: {
    type1 (value) {
      if (value === '0') return '实时探测模式'
      if (value === '1') return '睡眠探测模式'
      return '-'
    },
    type2 (value) {
      if (value === '0') return '关闭'
      if (value === '1') return '开启'
      return '-'
    }
  },
  methods: {
    async getInfo () {
      // 接口地址 https://doc.weixin.qq.com/doc/w3_AAMAewYpAFE2hPMGd1CQuet2QapJl?scode=ACwAmwdjAAYSQ8FW10
      try {
        const obj = {
          imei: '869840066030007',
          DataType: '1'
        }
        const { data: res } = await this.$http.post('/zhyyapi/findssjcjl', obj)
        console.log('健康实时监测 ', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        const arr = []
        arr.push(res.data)
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < arr.length; j++) {
            if (this.tableData[i].id === arr[j].id) {
              arr.splice(j, 1)
              i = 0
              j = 0
            }
          }
        }
        this.tableData = [...this.tableData, ...arr]
      } catch (error) {
        console.log('健康实时监测 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .tab-line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;

    .tab-text {
      height: 50px;
      line-height: 50px;
      margin-right: 20px;
      padding-left: 40px;
      font-size: 16px;
      box-sizing: border-box;
    }

    .slider {
      width: 20%;
    }
  }

  /deep/.el-table__cell {
    text-align: center;
  }
}
</style>

<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-06-01 13:58:24
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-06-05 08:26:59
 * @FilePath: \visions\src\views\zhyl\runkanban\pop\tab1.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab">
    <!-- <div class="tab-line">
      <div class="tab-text">
        <span>异常挣扎状态</span>
      </div>
      <el-radio-group v-model="params.abnormalStruggleState">
        <el-radio label="0">无</el-radio>
        <el-radio label="1">正常</el-radio>
        <el-radio label="2">异常</el-radio>
      </el-radio-group>
    </div> -->
    <el-table :data="tableData" border height="99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column label="异常挣扎状态">
        <template slot-scope="scope">
          {{ scope.row.abnormalStruggleState | type }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: null,
      params: {
        abnormalStruggleState: null
      },
      tableData: [
        // {
        //   abnormalStruggleState: null
        // }
      ]
    }
  },
  created () {
    this.getInfo()
    this.timer = setInterval(() => {
      this.getInfo()
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
  filters: {
    type (value) {
      if (value === '0') return '无'
      if (value === '1') return '正常'
      if (value === '2') return '异常'
      return '-'
    }
  },
  methods: {
    async getInfo () {
      // 接口地址 https://doc.weixin.qq.com/doc/w3_AAMAewYpAFE2hPMGd1CQuet2QapJl?scode=ACwAmwdjAAYSQ8FW10
      try {
        const obj = {
          imei: '869840066030007',
          DataType: '4'
        }
        const { data: res } = await this.$http.post('/zhyyapi/findssjcjl', obj)
        console.log('健康实时监测 ', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        const arr = []
        arr.push(res.data)
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < arr.length; j++) {
            if (this.tableData[i].id === arr[j].id) {
              arr.splice(j, 1)
              i = 0
              j = 0
            }
          }
        }
        this.tableData = [...this.tableData, ...arr]
      } catch (error) {
        console.log('健康实时监测 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .tab {
    width: 100%;
    height: 100%;

    .tab-line {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
      padding-left: 40px;
      box-sizing: border-box;

      .tab-text {
        height: 50px;
        line-height: 50px;
        margin-right: 20px;
        font-size: 16px;
      }

      .el-input {
        width: 100px;
      }
    }
    /deep/.el-table__cell {
      text-align: center;
    }
  }
</style>

<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-06-01 10:50:43
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-06-03 09:09:50
 * @FilePath: \visions\src\views\zhyl\runkanban\pop.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="pop">
    <div class="pop-box">
      <div class="pop-title">
        <span>健康实时监测</span>
      </div>
      <div class="pop-close" @click="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="pop-tab">
        <span v-for="(item, index) in tabs" :key="index" :class="{ 'pop-active': tabIndex === index }" @click="tabClick(index)" class="pop-item">{{ index + 1 + '.' + item }}</span>
      </div>
      <div class="pop-content">
        <Tab1 v-if="tabIndex === 0"></Tab1>
        <Tab2 v-else-if="tabIndex === 1"></Tab2>
        <Tab3 v-else-if="tabIndex === 2"></Tab3>
        <Tab4 v-else-if="tabIndex === 3"></Tab4>
      </div>
    </div>
  </div>
</template>

<script>
import Tab1 from './tab1.vue'
import Tab2 from './tab2.vue'
import Tab3 from './tab3.vue'
import Tab4 from './tab4.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4
  },
  data () {
    return {
      timer: null,
      tabIndex: 0,
      tabs: Object.freeze(['设备参数信息上报', '存在信息上报上报', '呼吸心率信息上报', '异常挣扎上报', '无人计时状态上报', '睡眠报告上报', '指令下发应答上报', '睡眠综合状态上报'])
    }
  },
  // created () {
  //   this.getInfo()
  //   this.timer = setInterval(() => {
  //     this.getInfo()
  //   }, 10000)
  // },
  // beforeDestroy () {
  //   clearInterval(this.timer)
  //   this.timer = null
  // },
  methods: {
    async getInfo () {
      // 接口地址 https://doc.weixin.qq.com/doc/w3_AAMAewYpAFE2hPMGd1CQuet2QapJl?scode=ACwAmwdjAAYSQ8FW10
      try {
        const obj = {
          imei: '869840066030007"',
          DataType: '2'
        }
        const { data: res } = await this.$http.post('/zhyyapi/findssjcjl', obj)
        console.log('健康实时监测 ', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('健康实时监测 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    close () {
      this.$parent.showPop = false
    }
  }
}
</script>

<style lang="less" scoped>
  .pop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 88;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);

    .pop-box {
      position: relative;
      width: 90%;
      height: 90%;
      background-color: #fff;
      border-radius: 10px;

      .pop-title {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 24px;
      }

      .pop-close {
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 28px;
        cursor: pointer;
      }

      .pop-tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: gray;
        color: #fff;
        font-size: 16px;

        .pop-item {
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          cursor: pointer;
        }

        .pop-active {
          background-color: #006EFF;
        }
      }

      .pop-content {
        overflow-y: scroll;
        width: 100%;
        height: calc(100%  - 90px);

        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }

  }
</style>

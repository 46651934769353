<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-06-01 13:58:24
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-06-05 08:26:49
 * @FilePath: \visions\src\views\zhyl\runkanban\pop\tab1.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab">
    <!-- <div class="tab-line">
      <div class="tab-text">
        <span>有人无人</span>
      </div>
      <el-radio-group v-model="params.exist">
        <el-radio label="1">有人</el-radio>
        <el-radio label="0">无人</el-radio>
      </el-radio-group>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>运动状态</span>
      </div>
      <el-radio-group v-model="params.movement">
        <el-radio label="2">活跃</el-radio>
        <el-radio label="1">静止</el-radio>
        <el-radio label="0">无</el-radio>
      </el-radio-group>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>体动</span>
      </div>
      <div class="slider">
        <el-slider :min="0" :max="100" v-model="params.body_motion"></el-slider>
      </div>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>离床状态</span>
      </div>
      <el-radio-group v-model="params.bed">
        <el-radio label="0">离床</el-radio>
        <el-radio label="1">入床</el-radio>
        <el-radio label="2">无</el-radio>
      </el-radio-group>
    </div>
    <div class="tab-line">
      <div class="tab-text">
        <span>睡眠状态</span>
      </div>
      <el-radio-group v-model="params.sleepStatus">
        <el-radio label="0">深睡</el-radio>
        <el-radio label="1">浅睡</el-radio>
        <el-radio label="2">清醒</el-radio>
        <el-radio label="3">无</el-radio>
      </el-radio-group>
    </div> -->
    <el-table :data="tableData" border height="99%">
      <el-table-column prop="id" label="id">
      </el-table-column>
      <el-table-column label="有人无人">
        <template slot-scope="scope">
          {{ scope.row.exist | type1 }}
        </template>
      </el-table-column>
      <el-table-column label="运动状态">
        <template slot-scope="scope">
          {{ scope.row.movement | type2 }}
        </template>
      </el-table-column>
      <el-table-column prop="body_motion" label="体动"></el-table-column>
      <el-table-column label="离床状态">
        <template slot-scope="scope">
          {{ scope.row.bed | type3 }}
        </template>
      </el-table-column>
      <el-table-column label="睡眠状态">
        <template slot-scope="scope">
          {{ scope.row.sleepStatus | type4 }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: null,
      params: {
        exist: null,
        movement: null,
        body_motion: null,
        bed: null,
        sleepStatus: null
      },
      tableData: [
        // {
        //   exist: null,
        //   movement: null,
        //   body_motion: null,
        //   bed: null,
        //   sleepStatus: null
        // }
      ]
    }
  },
  created () {
    this.getInfo()
    this.timer = setInterval(() => {
      this.getInfo()
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
  filters: {
    type1 (value) {
      if (value === '0') return '无人'
      if (value === '1') return '有人'
      return '-'
    },
    type2 (value) {
      if (value === '0') return '无'
      if (value === '1') return '静止'
      if (value === '2') return '活跃'
      return '-'
    },
    type3 (value) {
      if (value === '0') return '离床'
      if (value === '1') return '入床'
      if (value === '2') return '无'
      return '-'
    },
    type4 (value) {
      if (value === '0') return '深睡'
      if (value === '1') return '浅睡'
      if (value === '2') return '清醒'
      if (value === '3') return '无'
      return '-'
    }
  },
  methods: {
    async getInfo () {
      // 接口地址 https://doc.weixin.qq.com/doc/w3_AAMAewYpAFE2hPMGd1CQuet2QapJl?scode=ACwAmwdjAAYSQ8FW10
      try {
        const obj = {
          imei: '869840066030007',
          DataType: '2'
        }
        const { data: res } = await this.$http.post('/zhyyapi/findssjcjl', obj)
        console.log('健康实时监测 ', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        const arr = []
        arr.push(res.data)
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < arr.length; j++) {
            if (this.tableData[i].id === arr[j].id) {
              arr.splice(j, 1)
              i = 0
              j = 0
            }
          }
        }
        this.tableData = [...this.tableData, ...arr]
      } catch (error) {
        console.log('健康实时监测 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .tab {
    width: 100%;
    height: 100%;

    .tab-line {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;

      .tab-text {
        height: 50px;
        line-height: 50px;
        margin-right: 20px;
        padding-left: 40px;
        font-size: 16px;
        box-sizing: border-box;
      }

      .slider {
        width: 20%;
      }
    }

    /deep/.el-table__cell {
      text-align: center;
    }
  }
</style>

<template>
  <div class="pay">
    <div class="title">
      <span>付费</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="left">
          <div class="line">
            <div class="text">
              <span>应收：¥60.00</span>
            </div>
            <div class="text">
              <span>原价：¥60.00</span>
            </div>
            <div class="text">
              <span>折扣：¥0</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="table-title">
            <span>支付方式</span>
          </div>
          <div class="flex">
            <el-radio-group v-model="radio">
              <el-radio label="1">微信</el-radio>
              <el-radio label="2">支付宝</el-radio>
              <el-radio label="3">现金</el-radio>
              <el-radio label="4">医保卡</el-radio>
              <el-radio label="5">银联卡</el-radio>
              <el-radio label="6">账户余额</el-radio>
            </el-radio-group>
          </div>
          <div class="flex">
            <span>折扣额度</span>
            <el-select class="input" v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="flex">
            <span>实收金额</span>
            <el-input class="input" placeholder=""></el-input>
          </div>
          <div class="flex">
            <span>会员信息</span>
            <el-input class="input input1" placeholder="张飞/13572073686/优享会员/余额:300"></el-input>
            <span class="update el-icon-edit-outline"></span>
          </div>
          <div class="flex">
            <span>账户余额：500</span>
          </div>
          <div class="flex">
            <span>扣款后账户余额：430</span>
          </div>
          <div class="tip">
            <span>注：可选择共享人进行收费扣减，当前患者账户为0时，若存在共享人提示使用共享人费用扣减</span>
          </div>

          <div class="button">
            <el-button size="mini">取消</el-button>
            <el-button size="mini" class="blue" @click="openSuccess">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <Success v-if="showSuccess" @close="closeSuccess"></Success>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Success from './success.vue'
export default {
  components: {
    Success
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-08',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-06',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      radio: '',
      showSuccess: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () { },
  methods: {
    openSuccess () {
      this.showSuccess = true
    },
    closeSuccess () {
      this.showSuccess = false
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.pay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8888;
  width: 100%;
  height: 100%;
  padding: 0 300px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 500px;
    max-height: 500px;
    padding: 50px;
    padding-top: 40px;
    padding-bottom: 70px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 3%;
      right: 2%;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .box {
      display: flex;
      width: calc(100% - 20px);
      height: 100%;
      border-top: 2px dotted #fff;
      box-sizing: border-box;

      .left {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        width: 260px;
        height: 100%;
        padding: 0 24px;
        border-right: 2px dotted #fff;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .line {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 158px;
          color: #01EEFD;
          font-size: 18px;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        width: calc(100% - 260px);
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        .table-title {
          height: 40px;
          line-height: 40px;
          color: #01EEFD;
          font-size: 18px;
        }

        .flex {
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 10px;
          padding-left: 62px;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;

          .input {
            width: 120px;
            margin-left: 10px;
          }

          .input1 {
            width: 420px;
          }

          .update {
            margin-left: 20px;
            font-size: 36px;
          }

          .el-radio {
            color: #fff;
          }

          /deep/.el-radio__label {
            font-size: 16px;
          }

          /deep/.el-radio__input.is-checked+.el-radio__label {
            color: #01EEFD;
            border-color: #333;
          }

          /deep/.el-radio__input.is-checked .el-radio__inner {
            background-color: #01EEFD;
            border-color: #01EEFD;
          }
        }

        .tip {
          width: 100%;
          margin-top: 10px;
          padding-left: 62px;
          box-sizing: border-box;
          color: #FF0000;
          font-size: 14px;

          span {
            margin-right: 30px;
          }
        }

        .button {
          display: flex;
          align-items: center;
          height: 80px;

          .el-button {
            margin-left: 120px;
            background-color: #33465C;
            color: #fff;
            border: none;
          }

          .blue {
            background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
          }
        }
      }
    }
  }

}
</style>

<template>
  <div class="son">
    <div class="son-top">
      <div class="son-box">
        <div class="son-left">
          <div class="son-tab">
            <span v-for="(item, index) in tabs" :key="index" class="son-item"
              :class="{ 'son-active': tabIndex === index }" @click="tabClick(index)">{{ item }}</span>
          </div>
          <div class="son-line">
            <div class="son-title">
              <span>基础信息</span>
            </div>
          </div>
          <div class="son-line">
            <div class="son-title">
              <span>基础信息</span>
            </div>
            <el-input class="input" placeholder="请输入" suffix-icon="el-icon-search" size="small">
            </el-input>
          </div>
          <div class="son-line">
            <div class="son-title">
              <span>就诊类型</span>
            </div>
            <el-radio-group v-model="radio">
              <el-radio label="1">初诊</el-radio>
              <el-radio label="2">复诊</el-radio>
            </el-radio-group>
          </div>
          <div class="son-line son-line1">
            <div class="son-title">
              <span>*主诉：</span>
            </div>
            <el-input class="textarea" type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
            </el-input>
          </div>
          <div class="son-line son-line1 son-line2">
            <div class="son-item son-item1">
              <div class="son-title">
                <span>*诊断：</span>
              </div>
              <el-input class="textarea" type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
              </el-input>
            </div>
            <div class="son-item">
              <div class="son-title">
                <span>建册日期</span>
              </div>
              <el-date-picker class="input1" size="small" type="date" placeholder="年/月/日">
              </el-date-picker>
            </div>
            <div class="son-item">
              <div class="son-title">
                <span>孕周</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
            <div class="son-item">
              <div class="son-title">
                <span>计生证号</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
          </div>
          <div class="son-line son-line1">
            <div class="son-item">
              <div class="son-title">
                <span>体格检查：</span>
              </div>
            </div>
            <div class="son-flex">
              <div class="son-item">
              <div class="son-title">
                <span>体温</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
              <div class="son-unit">
                <span>℃</span>
              </div>
            </div>
            <div class="son-item">
              <div class="son-title">
                <span>血压：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
              <div class="son-unit">
                <span>/</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
              <div class="son-unit">
                <span>mmHg</span>
              </div>
            </div>
            <div class="son-item">
              <div class="son-title">
                <span>脉搏</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
              <div class="son-unit">
                <span>次/分</span>
              </div>
            </div>
            <div class="son-item">
              <div class="son-title">
                <span>呼吸</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
              <div class="son-unit">
                <span>次/分</span>
              </div>
            </div>
            <div class="son-item">
              <div class="son-title">
                <span>身高</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
              <div class="son-unit">
                <span>cm</span>
              </div>
            </div>
            <div class="son-item">
              <div class="son-title">
                <span>体重</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
              <div class="son-unit">
                <span>kg</span>
              </div>
            </div>
            <div class="son-item">
              <div class="son-title">
                <span>BIM指数</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
            </div>
          </div>
          <div class="son-line">
            <div class="son-title">
              <span>其他：</span>
            </div>
          </div>
          <div class="son-line son-line1 son-line2">
            <div class="son-item son-item2">
              <div class="son-title">
                <span>现病史：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
            <div class="son-item son-item2">
              <div class="son-title">
                <span>既往史：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
          </div>
          <div class="son-line son-line1 son-line2">
            <div class="son-item son-item2">
              <div class="son-title">
                <span>个人史：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
            <div class="son-item son-item2">
              <div class="son-title">
                <span>预防接种史：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
          </div>
          <div class="son-line son-line1 son-line2">
            <div class="son-item son-item2">
              <div class="son-title">
                <span>月经史：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
            <div class="son-item son-item2">
              <div class="son-title">
                <span>生育史：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
          </div>
          <div class="son-line son-line1 son-line2">
            <div class="son-item son-item2">
              <div class="son-title">
                <span>手术史：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
            <div class="son-item son-item2">
              <div class="son-title">
                <span>家族史：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
          </div>
          <div class="son-line son-line1 son-line2">
            <div class="son-item son-item2">
              <div class="son-title">
                <span>其他：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
            <div class="son-item son-item2">
              <div class="son-title">
                <span>辅助检查：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
          </div>
          <div class="son-line son-line1 son-line2">
            <div class="son-item son-item2">
              <div class="son-title">
                <span>处理意见：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
            <div class="son-item son-item2">
              <div class="son-title">
                <span>备注：</span>
              </div>
              <el-input class="input1" placeholder="" size="small">
              </el-input>
            </div>
          </div>
        </div>
        <div class="son-right">
          <div class="son-right-box">
            <div class="son-right-title">
              <span>历史病例</span>
            </div>
            <el-input size="mini" placeholder="请输入诊断名称" suffix-icon="el-icon-search">
            </el-input>
            <div class="son-right-scroll">
              <div class="scroll-item">
                <div class="scroll-item-top">
                  <div class="left">
                    <div class="line">
                      <span>初诊</span>
                      <span>科室：内科</span>
                      <span>诊断：上呼吸道感 </span>
                    </div>
                    <div class="line">
                      <span>就诊时间：2021-01-01</span>
                      <span>医生：王志</span>
                    </div>
                  </div>
                  <div class="right">
                    <span class="el-icon-arrow-down"></span>
                  </div>
                </div>
                <div class="scroll-item-bottom">
                  <span class="scroll-item-text">过敏史：头孢过敏</span>
                  <span class="scroll-item-text">*主诉：头晕、鼻塞、打喷嚏</span>
                  <span class="scroll-item-text">*诊断：上呼吸道感染</span>
                  <span class="scroll-item-text">现病史：暂无</span>
                  <span class="scroll-item-text">个人史：暂无</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="son-button">
      <el-button size="mini" @click="openCallMedicalRecords">调用病例</el-button>
      <el-button size="mini">存为模板</el-button>
      <el-button size="mini">打印</el-button>
      <el-button size="mini">保存</el-button>
      <el-button size="mini" @click="openCharge">收费</el-button>
      <el-button size="mini" class="gray" @click="openEndVisit">结束就诊</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['西药病例', '中医病例']),
      radio: '',
      textarea: ''
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openCallMedicalRecords () {
      this.$emit('openCallMedicalRecords')
    },
    openCharge () {
      this.$emit('openCharge')
    },
    openEndVisit () {
      this.$emit('openEndVisit')
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;

  .son-top {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-box {
      display: flex;
      width: 100%;

      .son-left {
        display: flex;
        flex-direction: column;
        width: calc(100% - 470px);
        border-right: 1px solid #01EEFD;
        box-sizing: border-box;

        .son-tab {
          display: flex;
          align-items: center;
          height: 34px;
          padding-left: 10px;
          box-sizing: border-box;

          .son-item {
            height: 34px;
            line-height: 34px;
            margin-right: 40px;
            border-bottom: 1px transparent solid;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
          }

          .son-active {
            color: #01EEFD;
            border-bottom: 1px #01EEFD solid;
          }
        }

        .son-line {
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 10px;
          padding: 0 10px;
          box-sizing: border-box;

          .son-title {
            margin-right: 4px;
            color: #fff;
            font-size: 14px;
          }

          .input {
            width: 220px;
          }

          .el-radio-group {
            margin-top: 2px;
          }

          /deep/.el-radio__inner {
            background-color: transparent;
          }

          /deep/.el-radio__label {
            color: #fff;
          }

          /deep/.el-radio__input.is-checked+.el-radio__label {
            color: #01EEFD;
          }

          .textarea {
            width: 500px;
          }

          .textarea1 {
            width: calc(100% - 120px);
          }

          /deep/.el-textarea__inner {
            overflow-y: scroll;
            resize: none;

            &::-webkit-scrollbar {
              width: 0;
            }
          }
        }

        .son-line1 {
          align-items: inherit;
        }

        .son-line2 {
          justify-content: space-between;
        }

        .son-line3 {
          flex-wrap: wrap;
        }

        .son-flex {
          display: flex;
          flex-wrap: wrap;
          width: calc(100% - 140px);

          .son-item {
            margin-right: 30px;
            margin-bottom: 10px;
          }
        }

        .son-item {
          display: flex;
          align-items: center;

          .input1 {
            width: 120px;
          }

          .son-unit {
            margin-left: 4px;
            color: #fff;
            font-size: 14px;
          }
        }

        .son-item1 {
          width: 50%;
          align-items: inherit;
        }

        .son-item2 {
          width: 50%;

          .input1 {
            width: calc(100% - 120px);
          }
        }
      }

      .son-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 470px;
        min-width: 470px;

        .son-right-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 389px;
          height: 684px;
          border: 4px solid #1D52A3;
          box-sizing: border-box;

          .son-right-title {
            width: 100%;
            height: 40px;
            line-height: 40px;
            padding-left: 17px;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
          }

          .el-input {
            width: calc(100% - 17px);
            margin-left: 17px;
          }

          .son-right-scroll {
            overflow-y: scroll;
            width: 100%;
            height: calc(100% - 80px);

            &::-webkit-scrollbar {
              width: 0;
            }

            .scroll-item {
              width: 100%;
              height: 300px;

              .scroll-item-top {
                display: flex;
                align-items: center;
                width: 100%;
                height: 70px;
                padding: 0 10px;
                background-color: #1D52A3;
                box-sizing: border-box;

                .left {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  width: calc(100% - 46px);
                  height: 100%;

                  .line {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    color: #fff;
                    font-size: 16px;
                  }
                }

                .right {
                  width: 46px;
                  text-align: center;
                  color: #fff;
                  font-size: 14px;
                }
              }

              .scroll-item-bottom {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                width: 100%;
                height: 230px;
                padding-left: 31px;
                box-sizing: border-box;

                .scroll-item-text {
                  color: #fff;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }

    .son-tab {
      width: 100%;
      height: 34px;
    }
  }

  .son-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;

    .el-button {
      margin: 0 25px;
      background-color: #1E58A6;
      color: #fff;
      border: none;
    }

    .gray {
      background-color: #909090;
    }
  }

}
</style>
